@if(isHeaderVisible){
  <nav class="header">
      <div class="header-content">
        <img src="HEQ_Logo.png" alt="Logo" class="header-logo" (click)="openDashboard()" />
        @if (isAuthor) {
          @if (!currentUrl.includes('template-builder')) {
        <ul class="nav-buttons">
          @for (button of navButtons; track button.id) {
            <li>
              <a [routerLink]="button.route" [routerLinkActive]="'active'" [class.active]="currentUrl === '/' + button.route">
                {{ button.label }}
              </a>
            </li>
          }
        </ul>
      }
        }
      </div>
      <div class="right-container">
        @if (!currentUrl.includes('template-builder')) {
        <div class="search-container">
          <input
            type="text"
            id="searchInput"
            [placeholder]="searchPlaceholder"
            [value]="searchText"
            (input)="onSearchInputChange($event)" />
          <svg
            class="search-icon"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24"
            height="25"
            viewBox="0 0 24 25" fill="none">
            <path d="M21.3187 20.5273L15.232 14.4406C16.1766 13.2195 16.6875 11.7266 16.6875 10.1562C16.6875 8.27656 15.9539 6.51406 14.6273 5.18516C13.3008 3.85625 11.5336 3.125 9.65625 3.125C7.77891 3.125 6.01172 3.85859 4.68516 5.18516C3.35625 6.51172 2.625 8.27656 2.625 10.1562C2.625 12.0336 3.35859 13.8008 4.68516 15.1273C6.01172 16.4562 7.77656 17.1875 9.65625 17.1875C11.2266 17.1875 12.7172 16.6766 13.9383 15.7344L20.025 21.8187C20.0428 21.8366 20.064 21.8508 20.0874 21.8604C20.1107 21.8701 20.1357 21.8751 20.1609 21.8751C20.1862 21.8751 20.2112 21.8701 20.2345 21.8604C20.2578 21.8508 20.279 21.8366 20.2969 21.8187L21.3187 20.7992C21.3366 20.7814 21.3508 20.7602 21.3604 20.7369C21.3701 20.7135 21.3751 20.6885 21.3751 20.6633C21.3751 20.638 21.3701 20.613 21.3604 20.5897C21.3508 20.5664 21.3366 20.5452 21.3187 20.5273ZM13.3688 13.8688C12.375 14.8602 11.0578 15.4062 9.65625 15.4062C8.25469 15.4062 6.9375 14.8602 5.94375 13.8688C4.95234 12.875 4.40625 11.5578 4.40625 10.1562C4.40625 8.75469 4.95234 7.43516 5.94375 6.44375C6.9375 5.45234 8.25469 4.90625 9.65625 4.90625C11.0578 4.90625 12.3773 5.45 13.3688 6.44375C14.3602 7.4375 14.9062 8.75469 14.9062 10.1562C14.9062 11.5578 14.3602 12.8773 13.3688 13.8688Z" fill="#555555"/>
          </svg>
        </div>
      }
      @if(currentUrl.includes('template-builder')){
        <app-nav-bar></app-nav-bar>
      }
        <div class="profile-container" (click)="toggleDropdown()">
          <div class="profile-icon">
            {{ userInitials }}
          </div>
          <span class="username">
            <ng-container *ngIf="firstLine">
              {{ firstLine }}<br *ngIf="secondLine" />
            </ng-container>
            <ng-container *ngIf="secondLine">
              {{ secondLine }}
            </ng-container>
          </span>
          <svg class="dropdown-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.3187 11.8706L13.8678 6.48059C14.0441 6.30802 14.0441 6.02899 13.8678 5.85641L13.1255 5.12943C12.9492 4.95686 12.6643 4.95686 12.4881 5.12943L8 9.48034L3.51195 5.12943C3.33572 4.95686 3.05078 4.95686 2.87455 5.12943L2.13217 5.85641C1.95594 6.02899 1.95594 6.30802 2.13217 6.48059L7.6813 11.8706C7.85753 12.0431 8.14247 12.0431 8.3187 11.8706Z" fill="black"/>
          </svg>
        </div>
      </div>
    </nav>
    <div *ngIf="isDropdownVisible" class="dropdown-menu">
      <button (click)="signOut()" class="dropdown-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.8195 17.7516H19.5586C19.4602 17.7516 19.3781 17.8336 19.3781 17.932V19.3805H4.61953V4.61953H19.3805V6.06797C19.3805 6.16641 19.4625 6.24844 19.5609 6.24844H20.8219C20.9203 6.24844 21.0023 6.16875 21.0023 6.06797V3.71953C21.0023 3.32109 20.6813 3 20.2828 3H3.71953C3.32109 3 3 3.32109 3 3.71953V20.2805C3 20.6789 3.32109 21 3.71953 21H20.2805C20.6789 21 21 20.6789 21 20.2805V17.932C21 17.8312 20.918 17.7516 20.8195 17.7516ZM21.2555 11.8523L17.9297 9.22734C17.8055 9.12891 17.625 9.21797 17.625 9.375V11.1562H10.2656C10.1625 11.1562 10.0781 11.2406 10.0781 11.3438V12.6562C10.0781 12.7594 10.1625 12.8438 10.2656 12.8438H17.625V14.625C17.625 14.782 17.8078 14.8711 17.9297 14.7727L21.2555 12.1477C21.2779 12.1301 21.296 12.1077 21.3085 12.0821C21.3209 12.0565 21.3274 12.0285 21.3274 12C21.3274 11.9715 21.3209 11.9435 21.3085 11.9179C21.296 11.8923 21.2779 11.8699 21.2555 11.8523Z"
            fill="#00857C"
          />
        </svg>
        Sign out
      </button>
    </div>
  }
  