import { Component, ViewChild, ElementRef, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CanvasElement } from '../../models/canvas-element';
import { CanvasEditorService } from '../../services/canvas-editor.service';
import { CanvasProperties } from '../../models/canvas-properties';
import {
  CanvasElementConfig,
  MappingElements,
  TemplateConfig,
} from '../../models/template-config';
import { PreviewTemplateComponent } from '../preview-template/preview-template.component';
import { CommonModule } from '@angular/common';
import { DesignMetadataResponse, endpoints } from '../../../../shared/constants/auth.constants';
import { DesignService } from '../../../../shared/services/design.service';
import { SharedDataService } from '../../../../shared/services/shared-data.service';
import { Store } from '@ngrx/store';
import { MappingsComponent } from '../mappings/mappings.component';
import { MatDialog } from '@angular/material/dialog';
import { TemplateService } from 'src/app/shared/services/template.service';
import { DialogType, PersonalizedResourceDialogsComponent } from './personalized-resource-dialogs/personalized-resource-dialogs.component';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DesignsService } from '@app/features/dashboard/services/designs.service';
import { AuthService } from '@app/core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, tap, catchError, throwError, forkJoin } from 'rxjs';
import { PersonalizedResourceLabels, PopupLabels, zone_border_width, zone_highlight_color } from '../../utils/editor-util';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ListviewComponent } from './listview/listview.component';
import { IElementConfig, IOptions } from '../../models/config-mapping.model';
import { CustomizableClickDialogComponent } from './customizable-click-dialog/customizable-click-dialog.component';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { environment } from '@env/environment';
import { SpinnerComponent } from "../../../../shared/components/spinner/spinner.component";
import { SpinnerService } from '@app/shared/services/spinner.service';
import { FormsModule } from '@angular/forms';
import { TbtStorageService } from '@app/shared/services/tbt-storage.service';

@Component({
  selector: 'app-personalized-resource',
  standalone: true,
  imports: [CommonModule, MappingsComponent, PreviewTemplateComponent,
    ListviewComponent, DropdownComponent, MatOption, MatLabel, MatSelect, 
    MatFormField, SpinnerComponent, FormsModule],
  templateUrl: './personalized-resource.component.html',
  styleUrl: './personalized-resource.component.scss',
})
export class PersonalizedResourceComponent implements OnInit, AfterViewInit {
  @ViewChild('listView') listView!: ListviewComponent;
  @ViewChild('canvas', { static: true }) canvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild(PreviewTemplateComponent)
  templatePreviewComponent!: PreviewTemplateComponent;
  labels = PersonalizedResourceLabels;
  presignedurls: string[] = [];
  disableHighlights = false;
  moduleUrls: { Path: string; url: string }[] = [];
  public highlightCustomizable: boolean = true;
  public isAuthor: boolean = false;
  public isApprover: boolean = false;
  public isAccountExecutive: boolean = false;
  public isCustomer: boolean = false;
  private masterTemplateConfig: any;
  designData: any;
  @Input() set masterTemplate(value: any) {
    this.masterTemplateConfig = value;
  }
  templateData: any;
  selectedModule: string = '';
  selectedElement: CanvasElementConfig | null = null;

  private originalElements: CanvasElement[] = [];
  public canvasProperties: CanvasProperties = new CanvasProperties();
  public canvasPages: CanvasElement[][] = [[]];
  public currentPageIndex = 0;
  public elements: CanvasElement[] = [];
  public masterElements: CanvasElement[] = [];
  public selectedElementIndex: number | null = null;
  public availableImages: string[] = [];
  public currentElement: CanvasElement | null = null;
  public isPreview: boolean = false;
  private isModified = false;
  showResetIcon: boolean = false;
  templateId: string | null = null;
  jobCode!: string;
  modules: any[] = [];
  mappedAssets: string[] = [];
  public visibleModules: string[] = [];
  accessToken: string | null = null;
  instanceUrl: string | null = null;
  // showSpinner: boolean = false;
  config_type: string = "";
  element_mapping_config!: any;
  private readonly undoStack: CanvasElement[][][] = [];
  private redoStack: CanvasElement[][][] = [];
  selectedLanguage: string = '';
  languages: string[] = [];
  canvasClass = 'a-four-landscape';
  showHighlightCheckbox = true;
  canSaveState = false;
  count = 0;
  canvasPagesLoaded: boolean[] = [];
  isPDFLoading = false;
  fontFamilies: string[] = [];

  constructor(
    private readonly canvasEditorService: CanvasEditorService,
    private readonly designService: DesignService,
    private readonly sharedDataService: SharedDataService,
    private readonly dialog: MatDialog,
    private readonly templateService: TemplateService,
    private readonly route: ActivatedRoute,
    private readonly designsService: DesignsService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly spinnerService: SpinnerService,
    private readonly tbtStorageService: TbtStorageService
  ) { }

  ngOnInit(): void {
    this.isAuthor = this.authService.isTemplateAuthor();
    this.isApprover = this.authService.isTemplateApprover();
    this.isAccountExecutive = this.authService.isAccountExecutive();
    this.isCustomer = this.authService.isCustomer();
    // this.initializeCanvasDimensions();
    this.spinnerService.show();
    this.authService.getSalesForceAccessToken().subscribe({
      next: token => {
        this.spinnerService.hide();
        this.getCanvasData();
      },
      error: err => {
        this.spinnerService.hide();
        console.error('Error loading access token:', err);
      },
    });

    // this.canvasEditorService.getModuleAssets().subscribe((modules: any[]) => {
    //   this.modules = modules;
    // });
  }

  ngAfterViewInit() {
    const canvasElement = this.canvas.nativeElement;
    canvasElement.addEventListener('click', (event) => {
      const rect = canvasElement.getBoundingClientRect();
      const clickX = (event.clientX - rect.left) * (canvasElement.width / rect.width);
      const clickY = (event.clientY - rect.top) * (canvasElement.height / rect.height);

      this.selectedElementIndex = null;
      this.elements.forEach((element, index) => {
        if (
          element.customizable &&
          clickX >= element.x &&
          clickX <= element.x + element.width &&
          clickY >= element.y &&
          clickY <= element.y + element.height
        ) {
          this.selectedElementIndex = index;
          this.onCustomizableSectionClick(element);
          this.draw();
        }
      });
      // const clickedElementIndex = this.elements.findIndex((element, index) => {
      //   if (
      //     element.customizable &&
      //     clickX >= element.x &&
      //     clickX <= element.x + element.width &&
      //     clickY >= element.y &&
      //     clickY <= element.y + element.height
      //   ) {
      //     return true;
      //   }
      //   return false;
      // });

      // if (clickedElementIndex !== -1 && clickedElementIndex !== this.selectedElementIndex) {
      //   this.selectedElementIndex = clickedElementIndex;
      //   const clickedElement = this.elements[clickedElementIndex];
      //   this.onCustomizableSectionClick(clickedElement);
      //   this.draw();
      // }
      this.isModified = true;

    });
  }

  getCanvasData() {
    const idp = this.tbtStorageService.getItem('idp', true);
    if (idp && idp === 'SF') {
      this.templateId = this.tbtStorageService.getItem('templateId');
      this.jobCode = this.tbtStorageService.getItem('jobCode') ?? '';
      this.getData();
    } else {
    this.route.paramMap.subscribe(async params => {
      this.templateId = params.get('templateId');
      this.jobCode = params.get('jobCode') ?? '';
      this.getData();
    });
    }
  }

  async getData () {
    this.spinnerService.show();
    const promises = [];
    if (this.templateId) {
      promises.push(this.fetchTemplateData(this.templateId));
    }
    if (this.jobCode) {
      promises.push(this.fetchDesignData(this.jobCode));
    }

    forkJoin(promises).subscribe({
      next: () => {
        this.isModified = false;
        this.loadCanvasConfiguration();
        // this.draw(); 
      },
      error: err => {
        this.spinnerService.hide();
        console.error('Error fetching data:', err);
      }
    });

    // await Promise.all(promises);
    
  }
  
  redirectToHomePage() {
    if (window.top) {
      window.top.location.href = environment.portalRedirectLink + endpoints.heqportal;
    }
  }
  redirectToSavedItemsPage() {
    if (window.top) {
      window.top.location.href = environment.portalRedirectLink + endpoints.savedItems;
    }
  }

  fetchTemplateData(templateId: string) {
    return this.templateService.getTemplateById(templateId).pipe(
      tap((response: any) => {
          this.templateData = response;
          console.log(this.templateData);

          this.getTemplateConfig();
  
          if (this.templateData.language_supported) {
            this.languages = this.templateData.language_supported.split(';').map((lang: string) => lang.trim());
          }
          this.selectedLanguage = this.templateData.template_language || '';
      })
    );
  }

  landscape = false;
  fetchDesignData(jobCode: string) {
    return this.designsService.getDesign(jobCode).pipe(
      tap((response: any) => {
        this.designData = response;
        if (this.designData) {
          try {
            const config = this.designData.canvas_properties ? JSON.parse(this.designData.canvas_properties) : null;
            if (config) {
              const width = config.documentWidth ? config.documentWidth : 0;
              const height = config.documentHeight ? config.documentHeight : 0;
              this.canvasProperties.canvasWidth = width ?? 2550;
              this.canvasProperties.canvasHeight = height ?? 3300;

              const portraitOrientation = config.documentOrientation === 'portrait';

              this.landscape = !portraitOrientation;

              this.canvas.nativeElement.style.width = portraitOrientation ? '550px' : '700px';
              this.canvas.nativeElement.style.height = portraitOrientation ? '700px' : '550px';
            }
          } catch (error) {
            console.error('Error parsing design data:', error);
          }
        }
      })
    );
  }

  onLanguageChange(event: any) {
    this.spinnerService.show();
    const newLanguage = event.value;
    if (this.templateId && this.templateData) {
      const masterTemplateId = this.templateData.master_template_id;
      const userId = this.templateData.cloned_user_id;
      
      this.templateService.languageSelected(masterTemplateId, newLanguage, userId).subscribe(
        response => {
          
          const templateId = response.template_id;
          const jobCode = response.job_code;
          
          this.tbtStorageService.setItem('templateId', templateId);
          this.tbtStorageService.setItem('jobCode', jobCode);

          const authToken = this.tbtStorageService.getItem('authToken', true);
          const role = this.tbtStorageService.getItem('userRole');
          this.spinnerService.hide();
  
          if (templateId && jobCode) {
            window.location.href = `${window.location.origin}/personalized-resource?authtoken=${authToken}&idp=SF&role=${role}&templateid=${templateId}&jobcode=${jobCode}`; // TODO: test with real url and data
          }
        },
        error => {
          this.spinnerService.hide();
          console.error('Error during language selection:', error);
        }
      );
    }
  }

  shouldShowLanguageDropdown(): boolean {
    return this.languages.length > 1;
  }

  addImageOrTextElement(newElement: CanvasElement) {
    this.canvasPages[this.currentPageIndex].push(newElement);
    this.selectPage(this.currentPageIndex);
    if (newElement.element_name) {
      this.canvasEditorService.notifyImageAdded(newElement.element_name);
    }
    this.saveState();
    this.isModified = true;
  }

  selectPage(index: number, onLoadCanvasRender = false) {
    if (this.count > 0) {
      this.count = 0;
    }
    if (this.config_type) {
      this.config_type = '';
    }
    this.currentPageIndex = index;
    this.elements = this.canvasPages[index].map(element => ({ ...element }));
    this.canvasPagesLoaded = Array(this.canvasPages.length).fill(false);
    if (this.elements.length > 0) {
      let customizableZones = 0;
      this.elements.forEach((element, index) => {
        if (element.customizable) {
          customizableZones++;
        }
      })
      this.showHighlightCheckbox = customizableZones > 0;
    }
    this.draw(onLoadCanvasRender);
  }

  draw(onLoadCanvasRender = false) {
    const ctx = this.canvas.nativeElement.getContext('2d', { willReadFrequently: true });
    if (!ctx) return;
    ctx.clearRect(
      0,
      0,
      this.canvasProperties.canvasWidth ?? 2550,
      this.canvasProperties.canvasHeight ?? 3300
    );
    ctx.save();
    ctx.translate(
      this.canvasProperties.zoomTranslateX,
      this.canvasProperties.zoomTranslateY
    );
    ctx.scale(this.canvasProperties.scale, this.canvasProperties.scale);
    
    for (let i = 0; i < this.elements.length; i++) {
      const element = this.elements[i];
      if (!element) continue;
      if (element.type === 'image') {
        this.drawImage(element, ctx, i === this.selectedElementIndex);
      } else if (element.type === 'text') {
        this.drawText(element, ctx, i === this.selectedElementIndex);
      }
      if (!this.disableHighlights) {
        if (element.customizable && (this.highlightCustomizable || this.selectedElementIndex === i)) {
          if (
            element.x !== undefined &&
            element.y !== undefined &&
            element.width !== undefined &&
            element.height !== undefined
          ) {
            this.highlightImage(
              element.x,
              element.y,
              element.width,
              element.height,
              this.selectedElementIndex === i
            );
            // this.drawDeleteButton(element);
          }
        }
      }
    }
    if (!onLoadCanvasRender) {
      this.isModified = true;
    }
    this.canvasPages[this.currentPageIndex] = this.elements;
    ctx.restore();
    this.canSave();
  }

  drawText(element: CanvasElement, ctx: CanvasRenderingContext2D, isSelected: boolean) {
    if (element.type === 'text') {
      // ctx.font = `${element.font_size ?? 33}px ${element.font_family ?? 'Invention'}`;

      const textConfig = this.templateData?.canvas_configuration?.find(
        (config: any) => config.type === 'text' && config.id === element.id
      );
      const fontSize = textConfig?.font_size || 33;

      const fontFamily = element.font_family || this.fontFamilies[0];
      
      ctx.font = `${fontSize}px ${fontFamily}`;

      ctx.fillStyle = element.color ?? 'black';
      ctx.textBaseline = 'top';
  
      const validTextAlign: CanvasTextAlign[] = ['left', 'right', 'center'];
      ctx.textAlign = validTextAlign.includes(element.element_name as CanvasTextAlign)
        ? (element.element_name as CanvasTextAlign)
        : 'left';
      const x = element.element_name === 'center'
          ? element.x + element.width / 2
          : element.element_name === 'right'
          ? element.x + element.width
          : element.x;
      // const x = element.x || 0;
      const y = element.y || 0;
  
      if (element.text) {
        let customizationIdText = element.text;
        if (this.isApprover) {
          customizationIdText = customizationIdText.replace(/{customization_id}/g, '');
        }
        // const lines = element.text.split('\\n');
        const lines = customizationIdText.split('\\n');
        const lineHeightFactor = 1.6;
        const lineHeight = (element.font_size ?? 33) * lineHeightFactor;
        
        lines.forEach((line, index) => {
          ctx.fillText(line, x, y + index * lineHeight);
        });
      }
    }
  }

  drawResizeHandle(element: CanvasElement) {
    const ctx = this.canvas.nativeElement.getContext('2d', { willReadFrequently: true });
    if (!ctx) return;

    const handleSize = 10;
    const handlePositions = [
      {
        x: element.x - handleSize / 2,
        y: element.y - handleSize / 2,
        cursor: 'nw-resize',
        handle: 'nw',
      },
      {
        x: element.x + element.width - handleSize / 2,
        y: element.y - handleSize / 2,
        cursor: 'ne-resize',
        handle: 'ne',
      },
      {
        x: element.x - handleSize / 2,
        y: element.y + element.height - handleSize / 2,
        cursor: 'sw-resize',
        handle: 'sw',
      },
      {
        x: element.x + element.width - handleSize / 2,
        y: element.y + element.height - handleSize / 2,
        cursor: 'se-resize',
        handle: 'se',
      },
    ];

    ctx.fillStyle = 'white';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;

    handlePositions.forEach(pos => {
      ctx.fillRect(pos.x, pos.y, handleSize, handleSize);
      ctx.strokeRect(pos.x, pos.y, handleSize, handleSize);
    });
  }

  highlightImage(x: number, y: number, width: number, height: number, selectedImage?: boolean) {
    if (this.disableHighlights) return;
    const ctx = this.canvas.nativeElement.getContext('2d', { willReadFrequently: true });
    if (!ctx) return;
    ctx.strokeStyle = zone_highlight_color;
    ctx.lineWidth = zone_border_width;
    ctx.strokeRect(x, y, width, height);
  }

  toggleHighlight(event: Event) {
    this.highlightCustomizable = (event.target as HTMLInputElement).checked;
    this.selectedElementIndex=null;
    this.disableHighlights=false;
    this.draw();
  }

  async drawImage(
    element: CanvasElement,
    ctx: CanvasRenderingContext2D,
    isSelected: boolean
  ) {
    if (element.type === 'image') {
      // console.log('Drawing image:', element);
      if (!element.file) {
        let img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        let url = "";
        Object.keys(this.presignedurls).forEach((key, index) => {
          if (key === decodeURIComponent(element.element_url)) {
            url = Object.values(this.presignedurls)[index];
          }
        });
        if (url) {
          if (this.count === 0) {
            if (this.canvasPages[this.currentPageIndex]?.length > 0) {
              this.spinnerService.show();
            } else {
              console.log("No elements to load for this page. Spinner not started.");
            }
          }
        }
        img.src = url;
        img.onload = () => {
          element.file = img;
          this.incrementAndCheckIfAllImagesLoaded();

          this.draw();
        };
        img.onerror = (e) => {
          console.error(`Failed to load image at URL: ${url} `, e);
          this.incrementAndCheckIfAllImagesLoaded();
        };
      } else {
        this.incrementAndCheckIfAllImagesLoaded();
        // const lerpFactor = 0.2;
        // element.x += ((element.targetX || element.x) - element.x) * lerpFactor;
        // element.y += ((element.targetY || element.y) - element.y) * lerpFactor;
        const eleme: any = this.designData.static_files.find((sf: any) => sf.section_id === element.section_id);
        if (eleme) {
          const props = eleme.properties;
          if (props) {
            const propsObj = JSON.parse(props);
            const isCustomizable = propsObj.is_customizable;
            if (isCustomizable) {
              const imageGroup = propsObj.imageGroup;
              const zoneGroup = propsObj.zoneGroup;
              if (!element.placeholder || element.placeholder === element.element_url) {
                // placeholder is empty and placeholder is filled with the zone image
                const {x, y, width, height} = zoneGroup;
                element.x = x;
                element.y = y;
                element.width = width;
                element.height = height;
              } else {
                const {x, y, width, height} = imageGroup;
                element.x = x;
                element.y = y;
                element.width = width;
                element.height = height;
              }
            } else {
              const imageGroup = propsObj.imageGroup;
              const {x, y, width, height} = imageGroup;
              element.x = x;
              element.y = y;
              element.width = width;
              element.height = height;
            }
          }
        }
        ctx.drawImage(
          element.file,
          element.x,
          element.y,
          element.width,
          element.height
        );
      }
    }

    ctx.fillStyle = 'transparent';
    ctx.fillRect(element.x, element.y, element.width, element.height);
    // if (isSelected) {
    //   this.drawResizeHandle(element);
    // }
  }


  incrementAndCheckIfAllImagesLoaded() {
    this.count++;
    let currentPageElements = this.canvasPages[this.currentPageIndex];
    if (currentPageElements?.length) {
      currentPageElements = [...new Set(currentPageElements)];
      currentPageElements = currentPageElements.filter((element: CanvasElement) => element.type === 'image');
    }

    let totalElements = currentPageElements?.length || 0;

    if (this.count === totalElements) {
      this.canvasPagesLoaded[this.currentPageIndex] = true;
      console.log("All images loaded for page:", this.currentPageIndex + 1);
      if (!this.isPDFLoading) {
        this.spinnerService.hide();
      }
      this.count = 0;
    }
  }

  // onCanvasClick(event: MouseEvent) {
  //   const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
  //   const clickX = event.clientX - canvasRect.left;
  //   const clickY = event.clientY - canvasRect.top;
  //   this.selectedElementIndex = null;
  //   this.elements.forEach((element, index) => {
  //     if (
  //       element.customizable &&
  //       clickX >= element.x &&
  //       clickX <= element.x + element.width &&
  //       clickY >= element.y &&
  //       clickY <= element.y + element.height
  //     ) {
  //       this.selectedElementIndex = index;
  //       this.onCustomizableSectionClick(element);
  //       this.draw();
  //     }
  //   });
  //   this.isModified = true;
  // }

  onCustomizableSectionClick(clickedElement: CanvasElement) {
    this.disableHighlights = false; 
    this.mappedAssets = [];
    if (clickedElement.customizable) {
      this.showResetIcon = true;
      if (!clickedElement.element_url && clickedElement.placeholder) {
        clickedElement.element_url = clickedElement.placeholder;
      }
      this.element_mapping_config = this.getMatchingMappingConfig(clickedElement);
      this.config_type = this.element_mapping_config.module_configuration.type.toLowerCase();
      switch (this.config_type) {
        case 'image':
          const matchingModule =
            this.element_mapping_config.module_configuration.module;
          if (matchingModule) {
            const moduleName = matchingModule.module_name;
            let filteredModulePaths = matchingModule.assets;
            this.openImagePopup(filteredModulePaths, clickedElement);
          } else {
            console.log('No matching mapping found for clicked element.');
          }
          break;
        case 'text':
          // this.updateImageInCustomizableSection(clickedElement);
          break;
        case 'list':
          if (this.selectedElementIndex && this.selectedElementIndex !== -1) {
            this.listView?.resetListView({elements: this.elements, elementIndex: this.selectedElementIndex});
          }
          break;
        case 'dropdown':
          console.log("dropdown");
          break;
        default:
          console.log('module configuration not found');
          break;
      }
    } else {
      this.showResetIcon = false;
    }
  }

  getMatchingMappingConfig(clickedElement: CanvasElement) {
    let matchingModuleConfig = this.templateData?.mappings?.find(
      (mapping: MappingElements) => mapping.zone_id === clickedElement.section_id
    );
    if (!matchingModuleConfig) {
      this.config_type = '';
      return;
    }
    matchingModuleConfig.module_configuration = this.templateData?.module_configuration.find((config: IElementConfig) => config.id == matchingModuleConfig.module_id)
    matchingModuleConfig.canvas_element = clickedElement;
    return matchingModuleConfig;
  }

  openImagePopup(assetImages: string[], clickedElement: CanvasElement): void {
    this.openCustomizableClickDialog(
      {
        images: assetImages,
        title: PopupLabels.OnCustomizableImageClick.title,
        templateName: this.templateData.template_name,
        job_code: this.templateData.job_code,
      },
      clickedElement
    );
  }

  openCustomizableClickDialog(
    options: {
      images: string[];
      title: string;
      templateName: string;
      job_code: string;
    },
    clickedElement: CanvasElement
  ): void {
    let dialogRef = this.dialog.open(CustomizableClickDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '800px',
      width: '1204px',
      backdropClass: 'custom-dialog',
      data: {
        images: options.images,
        title: options.title,
        templateName: options.templateName,
        job_code: options.job_code,
        elements: this.elements,
        elementIndex: this.selectedElementIndex        
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.selectedElementIndex !== null && this.selectedElementIndex >= 0) {
          let clickedElement = this.elements[this.selectedElementIndex];
          delete clickedElement.file;
          this.updateImageInCustomizableSection(clickedElement, result.pathname.substring(1));
        }
      }
      this.selectedElementIndex=null;
      this.disableHighlights=false;
    });
  }
  updateImageInCustomizableSection(
    clickedElement: CanvasElement,
    selectedImageUrl: string
  ) {
    if (clickedElement.customizable) {
      if (!clickedElement.placeholder) {
        clickedElement.placeholder = clickedElement.element_url;
      }
      clickedElement.element_url = selectedImageUrl;
      const matchingMapping = this.templateData?.mappings.find(
        (mapping: MappingElements) => mapping.zone_id === clickedElement.id
      );
      if (matchingMapping) {
        matchingMapping.asset_link = selectedImageUrl;
      }
      this.draw();
    }
  }
  resetCustomizableSection(): void {
    if (this.selectedElementIndex !== null) {
      const selectedElement = this.elements[this.selectedElementIndex];
      if (selectedElement && selectedElement.customizable) {
        if (
          selectedElement.placeholder &&
          selectedElement.element_url !== selectedElement.placeholder
        ) {
          selectedElement.element_url = selectedElement.placeholder;
          const matchingMapping = this.templateData?.mappings.find(
            (mapping: MappingElements) => mapping.zone_id === selectedElement.id
          );
          if (matchingMapping) {
            matchingMapping.asset_link = '';
          }
          const img = new Image();
          img.src = selectedElement.element_url + '?not-from-cache-please';
          img.onload = () => {
            selectedElement.file = img;
            this.drawAfterAllImagesLoaded();
          };
        }
      }
    }
  }
  private drawAfterAllImagesLoaded() {
    const imagesLoaded = this.elements.every(
      element => element.file instanceof HTMLImageElement
    );

    if (imagesLoaded) {
      this.draw();
    }
  }

  loadCanvasConfiguration(): void {
    const savedConfig = this.templateData;
    if (savedConfig) {
      this.elements = savedConfig.canvas_configuration || [];
    }
    this.spinnerService.hide();
  }

  doPreview() {
    if (this.elements.length > 0) {
      this.isPreview = true;
      this.draw(true);
      this.templatePreviewComponent.imageSrc =
        this.canvas.nativeElement.toDataURL('image/png');
      this.templatePreviewComponent.open();
      this.isPreview = false;
      this.draw(false);
    } else {
      alert('Template is Empty');
    }
  }

  doApproved(): void {
    if (this.isApprover) {
      const dialogRefContinue = this.dialog.open(
        PersonalizedResourceDialogsComponent,
        {
          backdropClass: 'custom-dialog',
          data: {
            type: 'confirmContinue',
            resourceName: this.templateData.template_name,
            onYes: this.handleApproval.bind(this),
            onNo: () => {
              console.log('Approval process canceled.');
            },
          },
          width: '712px',
          maxHeight: '241px',
        }
      );

      dialogRefContinue.afterClosed().subscribe(result => {
        if (result === 'yes') {
          console.log('Approval process completed.');
        } else {
          console.log('Approval was canceled or failed.');
        }
      });
    }
  }

  private handleApproval(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.templateData) {
        console.error('Template data is missing.');
        return reject();
      }
      const designName = this.templateData.design_name || 'design';
      const templateId = this.templateData.template_id || '';

      const templateConfig: TemplateConfig = {
        ...this.templateData,
        method_code: '2',
        status: 'PUBLISHED',
      };
      this.spinnerService.show();
      const status = 'Approved for Distribution';

      // TODO: Replace the loop with a more efficient method
      if (this.templateData.module_configuration) {      
        this.templateData.module_configuration.forEach((config: any) => {
          config.options.forEach((option: any) => {
            if ('preSignedUrl' in option) {
              delete option.preSignedUrl;
            }
          });
        });
      }


      console.log(templateConfig);
      this.updateTemplate(templateConfig)
        .then(() => this.generateAndUploadPDF(designName, templateId, status))
        .then(() => {
          this.spinnerService.hide();
          const successDialogRef = this.dialog.open(
            PersonalizedResourceDialogsComponent,
            {
              backdropClass: 'custom-dialog',
              data: {
                type: 'approveSuccess',
                resourceName: this.templateData.template_name,
              },
              width: '712px',
              maxHeight: '281px',
            }
          );

          successDialogRef.afterClosed().subscribe(() => {
            this.router.navigate(['/dashboard/templates']);
            console.log('Approval completed and navigation triggered.');
            resolve();
          });
        })
        .catch(error => {
          console.error(error);
          reject();
        });
    });
  }

  private updateTemplate(templateConfig: TemplateConfig): Promise<void> {
    this.spinnerService.show();
    return new Promise((resolve, reject) => {
      this.templateService.updateTemplate(templateConfig).subscribe(
        () => {
          this.spinnerService.hide();
          console.log('Template updated successfully');
          resolve();
        },
        error => {
          this.spinnerService.hide();
          console.error('Error updating template:', error);
          reject(error);
        }
      );
    });
  }

  private generateAndUploadPDF(
    designName: string,
    templateId: string,
    status: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.spinnerService.show();
      this.saveAsPDF()
        .then(pdfBase64 => {
          this.spinnerService.show();
          const jobcode = this.templateData.job_code;
          const template_id = this.isApprover ? templateId : this.templateData.master_template_id;
          this.templateService.createTemplatePDF(pdfBase64, designName, templateId, status, jobcode, template_id, 'Customizable', this.templateData.expiry_date)
          // this.templateService
          //   .contentVersionUpdateForApproval(pdfBase64, designName, templateId, status,jobcode, template_id, 'Customizable')
            .subscribe(
              () => {
                this.spinnerService.hide();
                console.log('PDF uploaded successfully');
                resolve();
              },
              error => {
                this.spinnerService.hide();
                console.error('Error uploading PDF to Salesforce:', error);
                reject(error);
              }
            );
        })
        .catch(error => {
          this.spinnerService.hide();
          console.error('Error generating PDF:', error);
          reject(error);
        });
    });
  }

  doRejected(): void {
    if (this.isApprover) {
      const dialogRef = this.dialog.open(PersonalizedResourceDialogsComponent, {
        backdropClass: 'custom-dialog',
        data: {
          type: 'confirmReject',
          resourceName: this.templateData.template_name,
          comments: '',
          onYes: (comments: string) => {
            if (this.templateData) {
              const templateConfig: TemplateConfig = {
                ...this.templateData,
                method_code: '2',
                status: 'REJECTED',
                comments: comments,
              };

              dialogRef.close();

              this.spinnerService.show();
              this.templateService.updateTemplate(templateConfig).subscribe(
                response => {
                  this.spinnerService.hide();
                  const successDialogRef = this.dialog.open(
                    PersonalizedResourceDialogsComponent,
                    {
                      backdropClass: 'custom-dialog',
                      data: {
                        type: 'rejectSuccess',
                        resourceName: this.templateData.template_name,
                        rejectComments: comments,
                      },
                      width: '712px',
                      maxHeight: '278px',
                    }
                  );
                  successDialogRef.afterClosed().subscribe(() => {
                    this.router.navigate(['/dashboard/templates']);
                    console.log(
                      'Rejection completed and navigation triggered.'
                    );
                  });
                },
                error => {
                  this.spinnerService.hide();
                  console.error('Error rejecting template:', error);
                }
              );
            }
          },
          onNo: () => {
            console.log('Rejection process canceled.');
          },
        },
        width: '712px',
        maxHeight: '405px',
      });

      dialogRef.afterClosed().subscribe(() => {
        console.log('Rejection confirmation popup closed.');
      });
    }
  }

  private fetchDesignMetadata(): void {
    const jobCode = this.sharedDataService.jobCode;
    const userId = this.sharedDataService.userId;
    const templateId = this.sharedDataService.templateId;
    const designName = this.sharedDataService.designName;

    if (jobCode && userId && templateId && designName) {
      this.designService
        .getDesignMetadata(jobCode, userId, templateId, designName)
        .subscribe(
          (metadataResponse: DesignMetadataResponse) => {
            console.log('Design Metadata Response:', metadataResponse);
          },
          error => {
            console.log('Error fetching design metadata:', error);
          }
        );
    } else {
      console.log('Required data not found in SharedDataService');
    }
  }

  async getTemplateConfig() {
    this.presignedurls = (await this.getPresignedImageURL()) as string[];
    const templateConfig: TemplateConfig | null = this.templateData
      ? this.templateData
      : null;
    if (templateConfig && Array.isArray(templateConfig.canvas_configuration)) {
      this.setCanvasPages(templateConfig);
      this.currentPageIndex = 0;
      this.elements = this.canvasPages[this.currentPageIndex];
      this.selectPage(this.currentPageIndex);
    } else {
      console.log('Invalid or undefined canvas_configuration');
    }
  }

  getPresignedImageURL() {
    this.spinnerService.show();
    return new Promise((resolve, reject) => {
      this.templateService
        .getDesigns(this.templateData.job_code)
        .subscribe((response: any) => {
          let presignedurls = response['presigned-urls'];
          if (!presignedurls) {
            return;
          }

          const fontUrls = Object.keys(presignedurls).filter((key) =>
            key.includes(`${this.jobCode}/design/fonts`)
          );

          fontUrls.forEach((fontUrl) => {
            const fontName = fontUrl.split("/").pop()?.split(".")[0];
            if (fontName) {
              const style = document.createElement("style");
              style.innerHTML = `
              @font-face {
                font-family: '${fontName}';
                src: url('${presignedurls[fontUrl]}') format('woff2');
                font-weight: normal;
                font-style: normal;
              }
            `;
              document.head.appendChild(style);
              this.fontFamilies.push(fontName);
            }
          });
          this.spinnerService.hide();
          resolve(presignedurls);
        },
        (error) => {
          console.error("Error fetching presigned URLs:", error);
          this.spinnerService.hide();
        }
      );
    });
  }
  setCanvasPages(templateConfig: TemplateConfig) {
    let canvasConfig = templateConfig.canvas_configuration;
    canvasConfig.forEach((item, index) => {
      let pageIndex = item.page_number as number;
      if (!this.canvasPages[pageIndex - 1]) {
        this.canvasPages.push([]);
      }
      this.canvasPages[pageIndex - 1].push(item);
    });
    return;
  }

  updateElement(updatedElement: CanvasElementConfig) {
    if (updatedElement) {
      this.elements = this.elements.map(element =>
        element.id === updatedElement.id ? updatedElement : element
      );
      this.draw();
    }
  }

  saveState() {
    const currentState = this.canvasPages.map(page =>
      page.map(element => ({ ...element }))
    );
    
    this.undoStack.push(currentState);
    this.redoStack = [];
  }

  initializeCanvasDimensions() {
    const canvasOuterContainer = document.getElementById('canvas-container');
    if (canvasOuterContainer) {
      this.setCanvasDimensions(canvasOuterContainer);
    }
  }

  setCanvasDimensions(canvasOuterContainer: HTMLElement) {
    // this.canvasProperties.canvasWidth = canvasOuterContainer.offsetWidth;
    // this.canvasProperties.canvasHeight = canvasOuterContainer.offsetHeight;
    // this.canvas.nativeElement.width = canvasOuterContainer.offsetWidth;
    // this.canvas.nativeElement.height = canvasOuterContainer.offsetHeight;
  }

  onAssetClick(assetURL: string): void {
    if (this.selectedElementIndex !== null) {
      const selectedElement = this.elements[this.selectedElementIndex];
      if (selectedElement && selectedElement.customizable) {
        selectedElement.element_url = assetURL;

        const img = new Image();
        img.src = assetURL;
        img.onload = () => {
          selectedElement.file = img;
          selectedElement.modified = true;
          this.draw();
        };
      }
    }
  }

  async saveAsPDF() {
    this.spinnerService.show();
    this.isPDFLoading = true;
    this.highlightCustomizable = false;
    this.disableHighlights = true; 
    this.draw(false);
    console.log('<==== save as pdf: ====> ', this.count, ' selected page index: ', this.currentPageIndex);
    
    const pdf = new jsPDF(this.landscape ? 'l' : 'p', 'px', [this.canvasProperties.canvasWidth, this.canvasProperties.canvasHeight],true);
    const scaleFactor = 0.1;
    for (const [pageIndex, pageElements] of this.canvasPages.entries()) {
      await this.selectPage(pageIndex, true);
      if (this.canvasPagesLoaded[pageIndex] === false) {
        await this.delay(pageIndex);
        await this.delayTimeOut(1000);
      }

      const canvasElement = this.canvas.nativeElement;

      const canvasImage = await html2canvas(canvasElement, {
        scale: 6,
        useCORS: true,
      });

      const canvasDataURL = canvasImage.toDataURL('image/jpeg', 1.0);

      if (pageIndex > 0) {
        pdf.addPage();
      }

      // const width = this.landscape ? 841.89 : 595.28;
      // const height = this.landscape ? 595.28 : 841.89;

      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      pdf.addImage(canvasDataURL, 'JPEG', 0, 0, width, height);
    }

    const pdfBase64 = pdf.output('datauristring').split(',')[1];
    // console.log(pdfBase64);
    // const filename = `${this.templateData.job_code}_1_scale.pdf`;
    // pdf.save(filename);
    this.isPDFLoading = false;
    this.spinnerService.hide();
    return pdfBase64;
  }

  private delay(pageIndex: number) {
    return new Promise(resolve => {
      const delayInterval = setInterval(() => {
        console.log('Interval page loaded: ', this.canvasPagesLoaded[pageIndex]);
        if (this.canvasPagesLoaded[pageIndex]) {
          clearInterval(delayInterval);
          resolve(true);
        }
      }, 1500);
    });
  }

  private delayTimeOut(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  canSave(): void {
    this.canSaveState = this.canvasPages.every(page => 
      page.every(element => {
        if (!element.customizable) {
          return true;
        }
        return element.placeholder !== null && element.placeholder !== element.element_url;
      })
    );
  }

  doFinish() {
    this.spinnerService.show();
    this.executeActions('PUBLISHED').subscribe(
      response => {
        this.spinnerService.show();
        this.saveAsPDF()
          .then(pdfBase64 => {
            const { design_name, template_id, job_code, customization_id, cloned_user_id, expiry_date } = this.templateData;
            const status = 'COMPLETED';
            const resourceType: "Customizable" | "Personalized" = "Personalized";
            this.spinnerService.hide();
            this.checkAndUploadContentVersion(pdfBase64, design_name, template_id, status, job_code,  customization_id, cloned_user_id, resourceType, expiry_date);
          })
          .catch(error => {
            console.error('Error generating PDF:', error);
            this.spinnerService.hide();
            this.openSnackbar('Error generating PDF. Please try again.');
          });
      },
      error => {
        console.error('Error updating template:', error);
        this.spinnerService.hide();
        this.openSnackbar('Error updating template. Please try again.');
      }
    );
  }

  openSnackbar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 10000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }

  doSaveAsDraft() {
    this.spinnerService.show();
    this.executeActions('DRAFT').subscribe(
      response => {
        this.saveAsPDF()
          .then(pdfBase64 => {
            const { design_name, template_id, job_code, customization_id, cloned_user_id, expiry_date} = this.templateData;
            const status = 'DRAFT';
            const resourceType: "Customizable" | "Personalized" = "Customizable";
            this.spinnerService.hide();
            this.checkAndUploadContentVersion(pdfBase64, design_name, template_id, status, job_code, customization_id, cloned_user_id,  resourceType, expiry_date);
          })
          .catch(error => {
            console.error('Error generating PDF:', error);
            this.spinnerService.hide();
            this.openSnackbar('Error generating PDF. Please try again.');
          });
      },
      error => {
        console.error('Error updating template:', error);
        this.spinnerService.hide();
        this.openSnackbar('Error updating template. Please try again.');
      }
    );
  }

  checkAndUploadContentVersion(
    pdfBase64: string,
    designName: string,
    templateId: string,
    status: string,
    jobcode: string,
    customization_id: string,
    clonedUserId: string,
    resourceType: "Customizable" | "Personalized",
    expiry_date: string
  ) {
    this.spinnerService.show();  
    const parent_template_id = this.isApprover ? templateId : this.templateData.master_template_id;
    this.spinnerService.show();
    this.templateService.contentVersion(pdfBase64, designName, templateId, status, jobcode, customization_id
      , clonedUserId ,resourceType, parent_template_id, expiry_date
    ).subscribe({
      next: () => {
        this.redirectToSavedItemsPage(); 
        // this.spinnerService.hide();
      },
      error: (error) => {
        console.error('Error updating PDF in Salesforce:', error);
        this.spinnerService.hide();
        this.openSnackbar('Error updating PDF. Please try again.');
      }
    }
    );
  
  }

  doCancel() {
    const dialogRefContinue = this.dialog.open(
      PersonalizedResourceDialogsComponent,
      {
        backdropClass: 'custom-dialog',
        data: {
          type: 'confirmCancel',
          resourceName: this.templateData.template_name,
          onYes: () => {
            this.doSaveAsDraft();
          },
          onNo: () => {
            this.spinnerService.show();
            this.redirectToHomePage();
          },
        },
        width: '552px',
        maxHeight: '265.22px',
      }
    );
  }

  private dialogDimensions: Record<DialogType, { width: string; maxHeight: string }> = {
    confirmContinue: { width: '400px', maxHeight: '200px' },
    approveSuccess: { width: '400px', maxHeight: '200px' },
    confirmReject: { width: '712px', maxHeight: '460px' },
    rejectSuccess: { width: '400px', maxHeight: '200px' },
    confirmCancel: { width: '450px', maxHeight: '400px' },
  };

  openDialog(
    dialogType: DialogType,
    options: { resourceName?: string; images?: string[] }
  ) {
    const dimensions = this.dialogDimensions[dialogType];

    return this.dialog.open(PersonalizedResourceDialogsComponent, {
      width: dimensions.width,
      maxHeight: dimensions.maxHeight,
      backdropClass: 'custom-dialog',
      data: {
        type: dialogType,
        resourceName: options.resourceName,
        images: options.images,
      },
    });
  }

  executeActions(status: string): Observable<any> {
    // this.spinnerService.show();
    this.updateElementPlaceholders();
    const updatedTemplateData = this.createUpdatedTemplateData(status);
    // TODO: Replace the loop with a more efficient method
    if (updatedTemplateData.module_configuration) {      
      updatedTemplateData.module_configuration.forEach((config: any) => {
        config.options.forEach((option: any) => {
          if ('preSignedUrl' in option) {
            delete option.preSignedUrl;
          }
        });
      });
    }
    return this.templateService.updateTemplate(updatedTemplateData).pipe(
      tap(response => {
      // this.spinnerService.hide();
        console.log(`Template ${status.toLowerCase()} successfully:`, response);
      }),
      catchError(error => {
      this.spinnerService.hide();
        console.error(
          `Error updating template as ${status.toLowerCase()}:`,
          error
        );
        return throwError(error);
      })
    );
  }

  updateElementPlaceholders() {
    this.elements.forEach(element => {
      if (element.element_url !== element.placeholder) {
        element.placeholder = element.placeholder || element.element_url;
        const matchingMapping = this.templateData?.mappings.find(
          (mapping: MappingElements) => mapping.zone_id === element.id
        );
        if (matchingMapping) {
          matchingMapping.asset_link = element.element_url;
        }
      }
    });
  }

  createUpdatedTemplateData(status: string) {
    return {
      ...this.templateData,
      status: status,
      method_code: '5',
      canvas_configuration: this.elements,
    };
  }

  previousPage() {
    if (!this.isFirstPage()) {
    if (this.currentPageIndex > 0) {
      this.selectPage(this.currentPageIndex - 1);
    }
  }
}

  nextPage() {
    if (!this.isLastPage()) {
    if (this.currentPageIndex < this.canvasPages.length - 1) {
      this.selectPage(this.currentPageIndex + 1);
    }
  }
}

  getVisiblePages(): number[] {
    const totalPages = this.canvasPages.length;
    const visiblePages = [];
  
    if (totalPages <= 3) {
      visiblePages.push(...Array.from({ length: totalPages }, (_, i) => i));
    } else {
      const start = Math.max(0, this.currentPageIndex - 1);
      const end = Math.min(totalPages - 1, this.currentPageIndex + 1);
  
      for (let i = start; i <= end; i++) {
        visiblePages.push(i);
      }
    }
  
    return visiblePages;
  }
  isFirstPage(): boolean {
    return this.currentPageIndex === 0; 
}

isLastPage(): boolean {
    return this.currentPageIndex === this.canvasPages.length - 1;
}

  onListViewLoaded(callback: (value: any) => void) {
    if (this.templateData?.template_type?.toLowerCase() === 'picklist') {
      callback({elements: this.elements, elementIndex: this.templateData.template_state});
    } else {
      const elementIndex = this.selectedElementIndex;
      if (elementIndex !== null && elementIndex !== -1) {
        callback({elements: this.elements, elementIndex});
      }
    }
  }

  drawSelectedConfig(event: IOptions[]) {
    console.log(event);
    if (this.selectedElementIndex != undefined) {
      const listIndex = event.length - 1;
      const url = event[listIndex] && event[listIndex].key != undefined ? event[listIndex].key : "";
      if (!this.elements[this.selectedElementIndex].placeholder) {
        this.elements[this.selectedElementIndex].placeholder = this.elements[this.selectedElementIndex].element_url;
      }
      this.elements[this.selectedElementIndex].element_url = url;
      console.log(this.elements);
      delete this.elements[this.selectedElementIndex].file;
      // this.selectedElementIndex = null;
      this.disableHighlights = false;
      console.log("===========================elements");
      console.log(this.elements);
      this.draw();
    }
  }

  drawSelectedDropDownConfig(event?: IOptions) {
    type elementType = "image" | "text";

    let thumbnailArray = event?.thumbnail && JSON.parse(event?.thumbnail);
    let canvasZones:CanvasElement[] = [];
    console.log('Thumbnails: ', thumbnailArray);
    
    thumbnailArray.forEach((item: string, index: number) => {
      let element = {
        "y": 0,
        "x": 0,
        "width":  this.canvasProperties.canvasWidth,
        "type": "image" as elementType,
        "placeholder": "",
        "page_number": index + 1,
        "is_dirty": false,
        "is_deleted": false,
        "id": index as unknown as string,
        "height":  this.canvasProperties.canvasHeight,
        "element_url": item,
        "element_name": item.substring(item.lastIndexOf("/") + 1),
        "customizable": true,
        "section_id": ""
      };
      canvasZones.push(element);
    })

    // this.templateData.canvas_configuration = canvasZones;
    let canvasArray;
    if (this.templateData?.template_type?.toLowerCase() === 'picklist') {
      const textConfig = this.templateData.canvas_configuration.filter((element: CanvasElement) => element.type === 'text');
      canvasArray = [...canvasZones, ...textConfig];
    } else {
      canvasArray = [...canvasZones];
    }
    console.log(canvasArray);
    // this.templateData.canvas_configuration.concat(canvasZones);
    this.templateData.canvas_configuration = canvasArray;
    console.log(canvasZones);
    console.log(this.templateData);
    
    this.getTemplateConfig();
    this.draw();
  }

  doClose() {
    // TODO: Check for Author test  
    this.router.navigate(['/dashboard']);
  }

  redo() {
    if (this.redoStack.length > 0) {
      const nextState = this.redoStack.pop();
      if (nextState) {
        this.undoStack.push(nextState);
        this.canvasPages = nextState.map(page =>
          page.map(element => ({ ...element }))
        );
        this.elements = this.canvasPages[this.currentPageIndex].map(
          element => ({ ...element })
        );
        this.canvasEditorService.notifyElementsChanged(this.elements);
        this.draw();
      }
    }
  }

  undo() {
    if (this.undoStack.length > 0) {
      const currentState = this.undoStack.pop();
      if (currentState) {
        this.redoStack.push(currentState);
      }
      const previousState = this.undoStack[this.undoStack.length - 1];
      if (previousState) {
        this.canvasPages = previousState.map(page =>
          page.map(element => ({ ...element }))
        );
      } else {
        this.canvasPages[this.currentPageIndex] = this.masterElements;
      }

      this.elements = this.canvasPages[this.currentPageIndex].map(element => ({
        ...element,
      }));
      this.canvasEditorService.notifyElementsChanged(this.elements);
      this.draw();
    }
  }
  zoomOut() {
    const scaleChange = 1.1;
    const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
    const centerX = canvasRect.width / 2;
    const centerY = canvasRect.height / 2;
    this.applyZoom(scaleChange, centerX, centerY);
  }
  zoomIn() {
    const scaleChange = 0.9;
    const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
    const centerX = canvasRect.width / 2;
    const centerY = canvasRect.height / 2;
    this.applyZoom(scaleChange, centerX, centerY);
  }
  private applyZoom(
    scaleChange: number,
    offsetX: number,
    offsetY: number
  ): void {
    this.canvasProperties.scale *= scaleChange;
    this.canvasProperties.zoomTranslateX =
      offsetX - (offsetX - this.canvasProperties.zoomTranslateX) * scaleChange;
    this.canvasProperties.zoomTranslateY =
      offsetY - (offsetY - this.canvasProperties.zoomTranslateY) * scaleChange;
    this.draw();
  }

  reset() {
    this.canvasProperties.scale = 1;
    this.canvasProperties.zoomTranslateX = 0;
    this.canvasProperties.zoomTranslateY = 0;
    this.draw();
  }

  
  onMouseUp(event?: MouseEvent) {
    this.saveState();
  }

}
