<div class="header-container">
  <span class="header">Configure module</span>
  <button class="close-button"mat-dialog-close>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M5.69343 4.27727C5.30291 3.88674 4.66975 3.88674 4.27922 4.27727C3.8887 4.66779 3.8887 5.30096 4.27922 5.69148L10.5858 11.9981L4.27922 18.3047C3.8887 18.6952 3.8887 19.3284 4.27922 19.7189C4.66975 20.1094 5.30291 20.1094 5.69343 19.7189L12 13.4123L18.3066 19.7189C18.6971 20.1094 19.3303 20.1094 19.7208 19.7189C20.1114 19.3284 20.1114 18.6952 19.7208 18.3047L13.4142 11.9981L19.7208 5.69148C20.1114 5.30096 20.1114 4.66779 19.7208 4.27727C19.3303 3.88674 18.6971 3.88674 18.3066 4.27727L12 10.5839L5.69343 4.27727Z" fill="#333333"/>
    </svg>
  </button>
</div>
<svg xmlns="http://www.w3.org/2000/svg" width="752" height="2" viewBox="0 0 910 2" fill="none">
  <path d="M0 1H910" stroke="#D9D9D9"/>
</svg>

<mat-dialog-content class="mat-typography">
  <div class="form">
    <form #configForm="ngForm" #form="ngForm">
      <div class="section1">
        <div class="form-row">
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Label</mat-label>
            <input matInput type="text" id="label" [(ngModel)]="configElement.label" name="label" required />
            @if (configForm?.controls?.['label']?.invalid && configForm?.controls?.['label']?.touched) {
            <mat-error>
              Label is required.
            </mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 100%;;">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="configElement.type" (selectionChange)="onTypeChange()" name="type" required>
              <mat-option value="image">Image</mat-option>
              <mat-option value="dropdown">Dropdown</mat-option>
              <mat-option value="list">List</mat-option>
            </mat-select>
            @if (configForm?.controls?.['type']?.invalid && configForm?.controls?.['type']?.touched) {
            <mat-error>
              Type is required.
            </mat-error>
            }
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="outline" style="width: 49%;">
            <mat-label>Select module</mat-label>
            <mat-select [(ngModel)]="configElement.module" name="module" required (selectionChange)="loadDesigns()">
              @for (module of moduleData; track module) {
              <mat-option [value]="module">{{module.name}}</mat-option>
              }
            </mat-select>
            @if (configForm?.controls?.['module']?.invalid && configForm?.controls?.['module']?.touched) {
            <mat-error>
              Module is required.
            </mat-error>
            }
          </mat-form-field>
        </div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" width="757" height="2" viewBox="0 0 910 2" fill="none">
        <path d="M0 1H910" stroke="#D9D9D9"/>
        </svg>

      @if(configElement.type == 'dropdown' || configElement.type == 'list'){
        <div class="section2">
          <div class="optionsDiv" style="display: flex; justify-content: space-between; align-items: flex-start;">
            <div style="width: 48%;">
              <div style="display: flex; justify-content: space-between; align-items: center;margin-bottom: 7px;">
                <p class="optionTitle">Create options</p>
                <button mat-button (click)="addOption()" appearance="outline" class="button" [class.disabled]="!isOptionValid()" [disabled]="!isOptionValid()"
                  style="margin-left: auto; display: block;">
                  Add option
                </button>
              </div>

              <mat-form-field appearance="outline" style="width: 30%;">
                <mat-label>Order</mat-label>
                <input matInput type="number" id="optionOrder" [(ngModel)]="configOption.order" name="optionOrder" />
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Enter option text</mat-label>
                <input matInput type="text" id="options" [(ngModel)]="configOption.label" name="optionLabel" />
              </mat-form-field>

              @if(configElement.type == 'dropdown') {
                @if(templateData?.template_type?.toLowerCase() === 'picklist') {
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Images</mat-label>
                    <mat-select [(ngModel)]="configOption.thumbnail" name="thumbnail"
                                (selectionChange)="updateThumbnail($event.value)" multiple>
                      @for (asset of filteredAssets; track asset) {
                      <mat-option [value]="asset.key">
                        <img [src]="asset.url" alt="asset" class="asset-img"
                             style="width: 30px; height: 30px; margin-right: 10px;">
                        {{asset.key}}
                      </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                } @else {
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Images</mat-label>
                    <mat-select [(ngModel)]="configOption.thumbnail" name="thumbnail"
                                (selectionChange)="updateThumbnail($event.value)">
                      @for (asset of filteredAssets; track asset) {
                      <mat-option [value]="asset.key">
                        <img [src]="asset.url" alt="asset" class="asset-img"
                             style="width: 30px; height: 30px; margin-right: 10px;">
                        {{asset.key}}
                      </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }
              } @else {
                <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>Images</mat-label>
                  <mat-select [(ngModel)]="configOption.thumbnail" name="thumbnail"
                              (selectionChange)="updateThumbnail($event.value)">
                    @for (asset of filteredAssets; track asset) {
                    <mat-option [value]="asset.key">
                      <img [src]="asset.url" alt="asset" class="asset-img"
                           style="width: 30px; height: 30px; margin-right: 10px;">
                      {{asset.key}}
                    </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
            </div>

            <div class="optionPreviewDiv">
              <div class="option-list-container">
                <label class="optionPreviewTitle">Preview</label>
                @for (option of configElement.options; track option) {
                <div class="option-item">
                  @if (option.thumbnail && getAssetUrl(option.thumbnail)) {
                  <img [src]="getAssetUrl(option.thumbnail)" alt="Thumbnail" class="thumbnail"
                       style="width: 30px; height: 30px; margin-right: 10px;">
                  }
                  <label>{{ option.label }}</label>
                </div>
                }
                @empty {
                <div> No preview available</div>
                }
              </div>
            </div>
          </div>
        </div>
      }
      <svg xmlns="http://www.w3.org/2000/svg" width="757" height="2" viewBox="0 0 910 2" fill="none">
        <path d="M0 1H910" stroke="#D9D9D9"/>
        </svg>
      <div class="section3">
      <div class="form-row">
        <!--  @if(configElement.type == 'dropdown' || configElement.type == 'list'){
        <mat-form-field appearance="outline" style="width: 60%;">
          <mat-label>Number of selection</mat-label>
          <input matInput type="number" id="noOfSelection" [(ngModel)]="configElement.noOfSelection"
            name="noOfSelection" required />
          @if (configForm.controls['noOfSelection']?.invalid && configForm.controls['noOfSelection']?.touched) {
          <mat-error>
            Number of selection is required.
          </mat-error>
          }
        </mat-form-field>
        } -->
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Help Text</mat-label>
          <input matInput type="text" id="helpText" [(ngModel)]="configElement.helpText" name="helpText" required />
          @if (configForm?.controls?.['helpText']?.invalid && configForm?.controls?.['helpText']?.touched) {
          <mat-error>
            Help Text is required.
          </mat-error>
          }
        </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Validation Error Message</mat-label>
        <input matInput type="text" id="validationMessage" [(ngModel)]="configElement.validationError"
          name="validationError" required />
        @if (configForm?.controls?.['validationError']?.invalid && configForm?.controls?.['validationError']?.touched) {
        <mat-error>
          Validation Error Message is required.
        </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
    </form>
</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="button cancel-button">Cancel</button>
  <button mat-button  cdkFocusInitial class="button save-button" [disabled]="configForm.invalid"
    (click)="submitForm()">Save</button>
</mat-dialog-actions>
