import { Component,ViewChild,ElementRef, Input, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavBarEvents } from '../../consts/nav-bar-events';
import { EditorHelper } from '../../helpers/editor-helper';
import { CanvasElement } from '../../models/canvas-element';
import { CanvasProperties } from '../../models/canvas-properties';
import { TemplateConfig } from '../../models/template-config';
import { CanvasEditorService } from '../../services/canvas-editor.service';
import { PreviewTemplateComponent } from '../preview-template/preview-template.component';
import { DesignMetadataResponse } from '@app/shared/constants/auth.constants';
import { DesignService } from '@app/shared/services/design.service';
import { SharedDataService } from '@app/shared/services/shared-data.service';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DesignsService } from '@app/features/dashboard/services/designs.service';
import { TemplateService } from '@app/shared/services/template.service';
import { selectDimensions, isCustomizable } from '@app/shared/store/selectors';
import { uuidv4 } from '@app/shared/helpers/tree-helpers';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { zone_border_width, zone_highlight_color } from '../../utils/editor-util';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-editor',
  standalone: true,
  imports: [PreviewTemplateComponent, CommonModule],
  templateUrl: './editor.component.html',
  styleUrl: './editor.component.scss'
})
export class EditorComponent implements OnInit, OnDestroy {
  @ViewChild('canvas', { static: true }) canvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild(PreviewTemplateComponent)
  templatePreviewComponent!: PreviewTemplateComponent;

  private masterTemplateConfig: any;
  @Input() set masterTemplate(value: any) {
    this.masterTemplateConfig = value;
  }
  @Input() templateData: any;

  presignedurls: string[] = [];
  public canvasProperties: CanvasProperties = new CanvasProperties();
  public canvasPages: CanvasElement[][] = [[]]; // Array to store pages
  public currentPageIndex = 0; // Index of the currently active page
  public elements: CanvasElement[] = [];
  public masterElements: CanvasElement[] = [];
  public selectedElementIndex: number | null = null;
  public availableImages: string[] = [];
  public currentElement: CanvasElement | null = null;
  private undoStack: CanvasElement[][][] = [];
  private redoStack: CanvasElement[][][] = [];
  activeCanvasIndex = 0;
  totalCanvases = 1;

  private offsetX!: number;
  private offsetY!: number;
  private dragging = false;
  private resizing = false;
  private resizeHandle: string | null = '';

  private navBarEventsSubscription!: Subscription;
  private elementPropertyChangeSubscription!: Subscription;
  // private isModified = false;
  templateId: string | null = null;
  jobCode: string | null | undefined;
  designData: any;
  public fontFamilies: string[] = [];

  constructor(
    private readonly canvasEditorService: CanvasEditorService,
    private readonly designService: DesignService,
    private readonly sharedDataService: SharedDataService,
    private readonly templateService: TemplateService,
    private readonly store: Store<any>,
    private readonly route: ActivatedRoute,
    private readonly designsService: DesignsService,
    private readonly spinnerService: SpinnerService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.subscribeToNavBarEvents();
    this.subscribeToPropertyUpdates();
    this.initializeCanvasDimensions();
    this.subscribeToCanvasEditorService();
    // this.fetchDesignMetadata();
    this.route.paramMap.subscribe(async params => {
      this.templateId = params.get('templateId');
      this.jobCode = params.get('jobCode');
      const promises = [];
      if (this.templateId) {
        promises.push(this.fetchTemplateData(this.templateId));
      }
      if (this.jobCode) {
        promises.push(this.fetchDesignData(this.jobCode));
      }
      await Promise.all(promises);
      // this.isModified = false;
      this.canvasEditorService.isModified = false; 
      // this.draw();

      const canvasElement = this.canvas.nativeElement;
      canvasElement.addEventListener('click', (event) => {
        const rect = canvasElement.getBoundingClientRect();
        const x = (event.clientX - rect.left) * (canvasElement.width / rect.width);
        const y = (event.clientY - rect.top) * (canvasElement.height / rect.height);


        this.selectedElementIndex = null;
        this.currentElement = null;
        this.currentElement = this.getImageAtCoordinates(x, y);
        this.selectedElementIndex = this.elements.findIndex(x => x === this.currentElement);
        this.canvasEditorService.setElementProperties(this.currentElement);
        this.draw();  // Redraw canvas to show selection outline

      });

    });
  }
  fetchTemplateData(templateId: string, isSave?: boolean) {
    this.spinnerService.show();
    this.templateService.getTemplateById(templateId).subscribe(
      {
        next: (response) => {
          this.spinnerService.hide();
          if (isSave) {
            this.templateData = response;
            // this.templateData.canvas_configuration.forEach((item: any) => {
            //   let filteredObject = response.canvas_configuration.filter((responseItem: any) =>
            //     item.section_id === responseItem.section_id
            //   );
            //   if(filteredObject.length>0){
            //     item.id = filteredObject[0].id;
            //     item.is_dirty= false;
            //   }
            //
            // });
            // Use a reference to the original array
            const currentPageElements = this.canvasPages[this.currentPageIndex];
  
            // Iterate in-place to update or remove elements
            for (let i = currentPageElements.length - 1; i >= 0; i--) {
              const oldElement = currentPageElements[i];
  
              // Find the matching newElement from the response
              const newElement = response.canvas_configuration.find(
                (x: CanvasElement) => x.section_id === oldElement.section_id
              );
  
              if (newElement) {
                // Replace the current element with the new one
                // currentPageElements[i] = newElement;
                currentPageElements[i].id =newElement.id;
                currentPageElements[i].is_dirty = false;
                currentPageElements[i].is_deleted = false;
              } else {
                // Remove the element if no match is found
                currentPageElements.splice(i, 1);
              }
            }
  
            // Ensure this.canvasPages is still tracked properly
            this.canvasPages[this.currentPageIndex] = [...currentPageElements];
  
            // this.canvasEditorService.setTemplateConfig = this.templateData;
            this.canvasEditorService.setCanvasConfiguration(this.templateData.canvas_configuration);
            this.canvasEditorService.setMappings(this.templateData.mappings);
            return;
          }
          this.getTemplateConfig();
        },
        error: (error) => {
          console.error('Error fetching template data:', error);
          this.spinnerService.hide();
        }
      }
    );
  }
  fetchDesignData(jobCode: string) {
    this.spinnerService.show();
    this.designsService.getDesign(jobCode).subscribe(
      {
        next: (designResponse) => {
          this.designData = designResponse;
          if (this.designData) {
            const config = this.designData.canvas_properties ? JSON.parse(this.designData.canvas_properties) : null;
            if (config) {
              const width = config.documentWidth ? config.documentWidth : 0;
              const height = config.documentHeight ? config.documentHeight : 0;
              this.canvasProperties.canvasWidth = width;
              this.canvasProperties.canvasHeight = height;
            }
          }
          this.spinnerService.hide();
        },
        error: (error) => {
          console.error('Error fetching template data:', error);
          this.spinnerService.hide();
        }
      }
    );
  }

  // Detect if a click is inside an image's bounds
  getImageAtCoordinates(x: number, y: number) {
    for (let i = this.elements.length - 1; i >= 0; i--) {
      const image = this.elements[i];
      if (
        x >= image.x &&
        x <= image.x + image.width &&
        y >= image.y &&
        y <= image.y + image.height
      ) {
        return image;
      }
    }
    return null;
  }

  // onMouseDown(event: MouseEvent) {
  //   const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
  //   const { mouseX, mouseY } = EditorHelper.getMouseCoordinates(
  //     event,
  //     canvasRect,
  //     this.canvasProperties
  //   );
  //   for (let i = this.elements.length; i--;) {
  //     const element = this.elements[i];
  //     this.resizeHandle = this.getResizeHandle(mouseX, mouseY, element);
  //     if (this.resizeHandle) {
  //       this.selectedElementIndex = i;
  //       this.currentElement = element;
  //       this.resizing = true;
  //       this.offsetX = mouseX;
  //       this.offsetY = mouseY;
  //       this.currentElementWidth = element.width;
  //       this.currentElementHeight = element.height;
  //       this.draw();
  //       return;
  //     }
  //     if (this.isOverImage(mouseX, mouseY, element)) {
  //       const handleX = element.x + element.width - 10;
  //       const handleY = element.y + element.height - 10;
  //       if (
  //         mouseX >= handleX &&
  //         mouseX <= handleX + 10 &&
  //         mouseY >= handleY &&
  //         mouseY <= handleY + 10
  //       ) {
  //         this.selectedElementIndex = i;
  //         this.currentElement = element;
  //         this.resizing = true;
  //         this.offsetX = mouseX - element.x;
  //         this.offsetY = mouseY - element.y;
  //         this.currentElementWidth = element.width;
  //         this.currentElementHeight = element.height;
  //         return;
  //       }
  //       this.selectedElementIndex = i;
  //       this.currentElement = element;
  //       this.dragging = true;
  //       this.offsetX = mouseX - element.x;
  //       this.offsetY = mouseY - element.y;
  //       element.targetX = element.x;
  //       element.targetY = element.y;
  //       element.is_deleted = false;
  //       element.is_dirty = true;
  //       this.canvasEditorService.setElementProperties(element);
  //       this.draw();
  //       return;
  //     }
  //   }
  //   this.selectedElementIndex = null;
  //   this.currentElement = null;
  //   this.canvasEditorService.setElementProperties(this.currentElement);
  //   this.draw();
  // }

  subscribeToCanvasEditorService() {
    this.canvasEditorService
      .getImageRemovedObservable()
      .subscribe((fileName: string) => {
        this.availableImages.push(fileName);
      });
  }

  isOverImage(mouseX: number, mouseY: number, element: CanvasElement): boolean {
    return (
      mouseX >= element.x &&
      mouseX <= element.x + element.width &&
      mouseY >= element.y &&
      mouseY <= element.y + element.height
    );
  }

  isOverDelete(mouseX: number, mouseY: number, element: CanvasElement) {
    const size = 20;
    const x = element.x + element.width - size;
    const y = element.y;
    return (
      mouseX >= x && mouseX <= x + size && mouseY >= y && mouseY <= y + size
    );
  }

  onMouseMove(event: MouseEvent) {
    const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
    const { mouseX, mouseY } = EditorHelper.getMouseCoordinates(
      event,
      canvasRect,
      this.canvasProperties
    );
    if (this.resizing && this.currentElement) {
      const newWidth = mouseX - this.offsetX;
      const newHeight = mouseY - this.offsetY;
      if (this.resizeHandle === 'se') {
        this.currentElement.width += newWidth;
        this.currentElement.height += newHeight;
      } else if (this.resizeHandle === 'sw') {
        this.currentElement.x += newWidth;
        this.currentElement.width -= newWidth;
        this.currentElement.height += newHeight;
      } else if (this.resizeHandle === 'ne') {
        this.currentElement.y += newHeight;
        this.currentElement.width += newWidth;
        this.currentElement.height -= newHeight;
      } else if (this.resizeHandle === 'nw') {
        this.currentElement.x += newWidth;
        this.currentElement.y += newHeight;
        this.currentElement.width -= newWidth;
        this.currentElement.height -= newHeight;
      }
      this.offsetX = mouseX;
      this.offsetY = mouseY;
      this.currentElement.is_dirty = true;
      this.currentElement.is_deleted = false;
      this.canvasEditorService.setElementProperties(this.currentElement);
      this.draw();
    } else if (this.dragging && this.currentElement) {
      this.currentElement.x = mouseX - this.offsetX;
      this.currentElement.y = mouseY - this.offsetY;
      this.draw();
    }
  }

  onMouseUp(event?: MouseEvent) {
    this.dragging = false;
    this.resizing = false;
    this.saveState();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Delete' && this.selectedElementIndex !== null) {
      this.deleteSelectedElement();
    }
  }

  deleteSelectedElement() {
    if (this.selectedElementIndex !== null) {
      const elementToRemove = this.elements[this.selectedElementIndex];
      if (elementToRemove.element_name) {
        this.canvasEditorService.notifyImageRemoved(elementToRemove.element_name);
      }
      // this.elements.splice(this.selectedElementIndex, 1);
      this.elements[this.selectedElementIndex].is_dirty = true;
      this.elements[this.selectedElementIndex].is_deleted = true;
      this.canvasEditorService.notifyElementMove(-2);

      this.selectedElementIndex = null;
      this.currentElement = null;
      this.canvasEditorService.setElementProperties(this.currentElement);
      this.draw();
      this.saveState();
    }
  }

  // deleteSelectedElement() {
  //   if (this.selectedElementIndex !== null) {
  //     const elementToRemove = this.elements[this.selectedElementIndex];
  //     if (elementToRemove.element_name) {
  //       this.canvasEditorService.notifyImageRemoved(elementToRemove.element_name);
  //     }
  //     this.elements[this.selectedElementIndex].is_dirty = true;
  //     this.elements[this.selectedElementIndex].is_deleted = true;
  //     this.selectedElementIndex = null;
  //     this.currentElement = null;
  //     this.canvasEditorService.setElementProperties(this.currentElement);
  //     this.draw();
  //     this.saveState();
  //   }
  // }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    let image: any = event.dataTransfer?.getData('text/plain');
    if (image) {
      image = JSON.parse(image);
      const img = new Image();
      image.crossOrigin = "Anonymous";
     // img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvasImage: CanvasElement = EditorHelper.prepareCanvasElement(
          img,
          1,
          this.canvas,
          this.canvasProperties,
          image,
          this.currentPageIndex
        );
        this.addImageOrTextElement(canvasImage);
        // if (canvasImage.element_name) {
        //   this.canvasEditorService.notifyImageAdded(canvasImage.element_name);
        // }
      };
      img.src = image.designUrl;

    }
  }

  addImageOrTextElement(newElement: CanvasElement) {
    newElement.page_number = this.currentPageIndex + 1;
    newElement.is_deleted = false;
    newElement.is_dirty = true;
    delete newElement.id;  //Removing the default id of the element. Id will come back from salesforce service
    this.canvasPages[this.currentPageIndex].push(newElement);
    this.selectPage(this.currentPageIndex);

    if (newElement.element_name) {
      this.canvasEditorService.notifyImageAdded(newElement.element_name);
    }
    this.redoStack = [];
    this.saveState();
  }

  selectPage(index: number, onLoadCanvasRender = false) {
    this.currentPageIndex = index;
    this.elements = this.canvasPages[index].map(element => ({ ...element }));
    this.draw(onLoadCanvasRender);
  }

  draw(onLoadCanvasRender = false) {
    const ctx = this.canvas.nativeElement.getContext('2d');
    if (!ctx) return;
    ctx.clearRect(
      0,
      0,
      this.canvasProperties.canvasWidth,
      this.canvasProperties.canvasHeight
    );

    ctx.fillStyle = 'white';
    ctx?.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    ctx.save();
    ctx.translate(
      this.canvasProperties.zoomTranslateX,
      this.canvasProperties.zoomTranslateY
    );
    ctx.scale(this.canvasProperties.scale, this.canvasProperties.scale);
    for (let i = 0; i < this.elements.length; i++) {
      const element = this.elements[i];
      if (!element || element.is_deleted) continue;
      if (element.type === 'image') {
        this.drawImage(element, ctx, i === this.selectedElementIndex);
      } else if (element.type === 'text') {
        this.drawText(element, ctx, i === this.selectedElementIndex);
      }
      if (this.selectedElementIndex === i) {
        if (
          element.x !== undefined &&
          element.y !== undefined &&
          element.width !== undefined &&
          element.height !== undefined
        ) {
          this.highlightImage(
            element.x,
            element.y,
            element.width,
            element.height
          );
          // this.drawDeleteButton(element);
        }
      }
    }
    if (!onLoadCanvasRender) {
      // this.isModified = true;
      this.canvasEditorService.isModified = true; 
    }
    this.canvasPages[this.currentPageIndex] = this.elements;
    ctx.restore();
  }

  async drawImage(
    element: CanvasElement,
    ctx: CanvasRenderingContext2D,
    isSelected: boolean
  ) {
    if (element.type === 'image') {
      if (!element.file) {
        let img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        let url = "";
        Object.keys(this.presignedurls).forEach((key, index) => {
          if (key == element.element_url) {
            url = Object.values(this.presignedurls)[index];
          }
        });
        if (url) {
          this.spinnerService.show();
        }
        img.src = url;
        img.onload = () => {
          element.file = img;
          this.draw();
          this.spinnerService.hide();
        };
      } else {
        const lerpFactor = 0.2;
        element.x += ((element.targetX || element.x) - element.x) * lerpFactor;
        element.y += ((element.targetY || element.y) - element.y) * lerpFactor;
        ctx.drawImage(
          element.file,
          element.x,
          element.y,
          element.width,
          element.height
        );
      }
    }
    ctx.fillStyle = 'transparent';
    ctx.fillRect(element.x, element.y, element.width, element.height);
    if (isSelected) {
      // this.drawResizeHandle(element);
    }
  }

  addText(): void {
    this.selectedElementIndex = null;
    this.currentElement = null;
    const fileId = uuidv4();
    const textElement: CanvasElement = {
      type: 'text',
      x: 150,
      y: 2944,
      width: 1150,
      height: 271,
      text: 'Enter text',
      font_size: 33,
      font_family: 'Invention',
      color: '#000000',
      customizable: false,
      element_name: '',
      element_url: '',
      page_number: this.currentPageIndex + 1,
      section_id: fileId,
      is_dirty: true,
      is_deleted: false,
    };
    this.elements.push(textElement);
    this.canvasEditorService.setElementProperties(textElement);
    this.draw();
  }

  drawText(element: CanvasElement, ctx: CanvasRenderingContext2D, isSelected: boolean) {
    if (element.type === 'text') {
      // ctx.font = `${element.font_size ?? 33}px ${element.font_family ?? 'Invention'}`;
      const textConfig = this.templateData?.canvas_configuration?.find(
        (config: any) => config.type === 'text' &&
        (config.id === element.id || config.section_id === element.section_id)
      );
      const fontSize = textConfig?.font_size || 33;

      const fontFamily = element.font_family ?? this.fontFamilies[0];
      
      ctx.font = `${fontSize}px ${fontFamily}`;
      ctx.fillStyle = element.color ?? 'black';
      ctx.textBaseline = 'top';
      const validTextAlign: CanvasTextAlign[] = ['left', 'right', 'center'];
      ctx.textAlign = validTextAlign.includes(element.element_name as CanvasTextAlign)
        ? (element.element_name as CanvasTextAlign)
        : 'left';
      const x = element.element_name === 'center'
          ? element.x + element.width / 2
          : element.element_name === 'right'
          ? element.x + element.width
          : element.x;
      // const x = element.x || 0;
      const y = element.y || 0;
  
      if (element.text) {
        const lines = element.text.split('\\n');
        const lineHeightFactor = 1.6;
        const lineHeight = (element.font_size ?? 33) * lineHeightFactor;
        
        lines.forEach((line, index) => {
          ctx.fillText(line, x, y + index * lineHeight);
        });
      }
    }
  }

  drawResizeHandle(element: CanvasElement) {
    const ctx = this.canvas.nativeElement.getContext('2d');
    if (!ctx) return;

    const handleSize = 40;
    const handlePositions = [
      {
        x: element.x - handleSize / 2,
        y: element.y - handleSize / 2,
        cursor: 'nw-resize',
        handle: 'nw',
      },
      {
        x: element.x + element.width - handleSize / 2,
        y: element.y - handleSize / 2,
        cursor: 'ne-resize',
        handle: 'ne',
      },
      {
        x: element.x - handleSize / 2,
        y: element.y + element.height - handleSize / 2,
        cursor: 'sw-resize',
        handle: 'sw',
      },
      {
        x: element.x + element.width - handleSize / 2,
        y: element.y + element.height - handleSize / 2,
        cursor: 'se-resize',
        handle: 'se',
      },
    ];

    ctx.fillStyle = 'white';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;

    handlePositions.forEach(pos => {
      ctx.fillRect(pos.x, pos.y, handleSize, handleSize);
      ctx.strokeRect(pos.x, pos.y, handleSize, handleSize);
    });
  }

  getResizeHandle(
    mouseX: number,
    mouseY: number,
    obj: CanvasElement
  ): string | null {
    const handleSize = 10;
    const handlePositions = [
      { x: obj.x - handleSize / 2, y: obj.y - handleSize / 2, handle: 'nw' },
      {
        x: obj.x + obj.width - handleSize / 2,
        y: obj.y - handleSize / 2,
        handle: 'ne',
      },
      {
        x: obj.x - handleSize / 2,
        y: obj.y + obj.height - handleSize / 2,
        handle: 'sw',
      },
      {
        x: obj.x + obj.width - handleSize / 2,
        y: obj.y + obj.height - handleSize / 2,
        handle: 'se',
      },
    ];

    for (const pos of handlePositions) {
      if (
        mouseX >= pos.x &&
        mouseX <= pos.x + handleSize &&
        mouseY >= pos.y &&
        mouseY <= pos.y + handleSize
      ) {
        return pos.handle;
      }
    }
    return null;
  }

  highlightImage(x: number, y: number, width: number, height: number) {
    const ctx = this.canvas.nativeElement.getContext('2d');
    if (!ctx) return;

    ctx.strokeStyle = zone_highlight_color;
    ctx.lineWidth = zone_border_width;
    ctx.strokeRect(x, y, width, height);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  get currentElementWidth(): number {
    return this.currentElement ? this.currentElement.width : 0;
  }

  set currentElementWidth(value: number) {
    if (this.currentElement) {
      this.currentElement.width = value;
      this.draw();
    }
  }

  get currentElementHeight(): number {
    return this.currentElement ? this.currentElement.height : 0;
  }

  set currentElementHeight(value: number) {
    if (this.currentElement) {
      this.currentElement.height = value;
      this.draw();
    }
  }

  subscribeToNavBarEvents() {
    this.navBarEventsSubscription = this.canvasEditorService
      .getNavBarEventsObservable()
      .subscribe(eventName => {
        switch (eventName) {
          case NavBarEvents.Save:
            this.onSave();
            break;
          case NavBarEvents.Preview:
            this.doPreview();
            break;
          case NavBarEvents.Test:
            // Test logic goes here
            break;
          case NavBarEvents.Submit:
            this.onSubmit();
            break;
        }
      });
  }

  onSubmit(): void {
    if (this.elements.length > 0) {
      const elementConfig = [...this.canvasPages.flat()];
      const masterTemplateConfig = this.masterTemplateConfig;
      const comments = this.canvasEditorService.getComments();
      this.canvasEditorService.setCanvasConfiguration(elementConfig);
      this.canvasEditorService.doSave(
        masterTemplateConfig,
        'submit',
        comments,
        'SUBMITTED'
      );

      // this.isModified = false;
      this.canvasEditorService.isModified = true; 
    }
  }

  loadCanvasConfiguration(): void {
    const savedConfig = this.templateData;
    if (savedConfig) {
      this.elements = savedConfig.canvas_configuration || [];
    }
  }

  onSave(): void {
    if (this.canvasEditorService.isModified && this.elements.length > 0) {
      this.draw();
      const elementConfig = [...new Set(this.canvasPages.flat())];
      const masterTemplateConfig = this.masterTemplateConfig;
      this.canvasEditorService.setCanvasConfiguration(elementConfig);
      this.canvasEditorService.doSave(
        masterTemplateConfig,
        'save',
        '',
        'DRAFT'
      ).then(() => {
        this.fetchTemplateData(this.templateData.template_id, true);
      });
      // this.isModified = false;
      this.canvasEditorService.isModified = true; 

      alert('Template Saved');
    } else if (this.elements.length === 0) {
      alert('Template is Empty');
    } else if (!this.canvasEditorService.isModified) {
      alert('Template is UnChanged');
    }
  }

  doPreview() {
    if (this.elements.length > 0) {
      this.templatePreviewComponent.imageSrc =
        this.canvas.nativeElement.toDataURL('image/png');
      this.templatePreviewComponent.open();
    } else {
      alert('Template is Empty');
    }
  }

  drawDeleteButton(element: CanvasElement) {
    const ctx = this.canvas.nativeElement.getContext('2d');
    if (!ctx) return;

    const size = 20;
    const x = element.x + element.width - size;
    const y = element.y;

    ctx.fillStyle = 'red';
    ctx.fillRect(x, y, size, size);

    ctx.fillStyle = 'white';
    ctx.font = '16px Invention';
    ctx.fillText('X', x + 5, y + 15);
  }

  private fetchDesignMetadata(): void {
    const jobCode = this.sharedDataService.jobCode;
    const userId = this.sharedDataService.userId;
    const templateId = this.sharedDataService.templateId;
    const designName = this.sharedDataService.designName;

    if (jobCode && userId && templateId && designName) {
      this.designService
        .getDesignMetadata(jobCode, userId, templateId, designName)
        .subscribe(
          (metadataResponse: DesignMetadataResponse) => {
          },
          error => {
            console.log('Error fetching design metadata:', error);
          }
        );
    } else {
      console.log('Required data not found in SharedDataService');
    }
  }

  async getTemplateConfig(isSave?: boolean) {
    this.presignedurls = await this.getPresignedImageURL() as string[];
    const templateConfig: TemplateConfig | null = this.templateData;
    //  this.canvasEditorService.setModuleConfiguration(this.templateData.module_configuration);

    if (templateConfig && Array.isArray(templateConfig.canvas_configuration)) {
      this.setCanvasPages(templateConfig);
      this.currentPageIndex = 0;
      this.selectPage(this.currentPageIndex);
    } else {
      console.log('Invalid or undefined canvas_configuration');
    }
  }

  getPresignedImageURL() {    
    this.spinnerService.show();
    return new Promise((resolve, reject) => {
      this.templateService.getDesigns(this.templateData.job_code).subscribe((response: any) => {
        let presignedurls = response['presigned-urls'];
         // fetch fonts from URLs START
        if (!presignedurls) {
          return;
        }
    
        const fontUrls = Object.keys(presignedurls).filter((key) =>
          key.includes(`${this.jobCode}/design/fonts`) 
        );
    
        fontUrls.forEach((fontUrl) => {
          const fontName = fontUrl.split('/').pop()?.split('.')[0];
          if (fontName) {
            const style = document.createElement('style');
            style.innerHTML = `
              @font-face {
                font-family: '${fontName}';
                src: url('${presignedurls[fontUrl]}') format('woff2');
                font-weight: normal;
                font-style: normal;
              }
            `;
            document.head.appendChild(style);
            this.fontFamilies.push(fontName);
          }
        }); // fetch fonts from URLs END
        this.spinnerService.hide();
        resolve(presignedurls);
      },
      (error) => {
        console.error("Error fetching presigned URLs:", error);
        this.spinnerService.hide();
      }
    );
  });
  }

  setCanvasPages(templateConfig: TemplateConfig) {
    let canvasConfig = templateConfig.canvas_configuration;
    canvasConfig.forEach((item, index) => {
      let pageIndex = item.page_number as number;
      if (!this.canvasPages[pageIndex - 1]) {
        this.canvasPages.push([]);
      }
      if (pageIndex > 0) {
        this.canvasPages[pageIndex - 1].push(item);
      }
    })
    return;
  }
  updateElement(updatedElement: Partial<CanvasElement>) {
    if (this.currentElement) {
      const index = this.elements.findIndex(e => e.section_id === this.currentElement?.section_id);
      if (index !== -1) {
        updatedElement.is_dirty = true;
        updatedElement.is_deleted = false;
        Object.assign(this.elements[index], updatedElement);
        this.draw();
      }
    }
  }

  subscribeToPropertyUpdates() {
    this.store.select(selectDimensions).subscribe(properties => {
      if (this.currentElement) {
        this.currentElement.height = properties.height;
        this.currentElement.width = properties.width;
        this.updateElement(this.currentElement);
      }
    });

    this.store.select(isCustomizable).subscribe(customizable => {
      if (this.currentElement) {
        this.currentElement.customizable = customizable;
        this.updateElement(this.currentElement);
      }
    });
    this.elementPropertyChangeSubscription = this.canvasEditorService
      .getElementPropertyChangeObservable()
      .subscribe(updatedElement => {
        if (updatedElement) {
          this.updateElement(updatedElement);
        }
      });
    this.elementPropertyChangeSubscription = this.canvasEditorService
      .getElementMoveObservable()
      .subscribe(stepUp => {
        const currentElement = this.currentElement;
        if (currentElement) {
          // TODO: replace element_name with id of the section
          const currentElementIndex = this.elements.findIndex(
            element => element.element_name === currentElement.element_name
          );

          if (currentElementIndex !== -1) {
            // TODO: replace element_name with id of the section
            this.elements = this.elements.filter(
              element => element.element_name !== currentElement.element_name
            );

            const newIndex = Math.min(
              Math.max(currentElementIndex + stepUp, 0),
              this.elements.length
            );

            this.elements.splice(newIndex, 0, currentElement);
            this.draw();
          }
        }
      });
  }

  saveState() {
    const currentState = this.canvasPages.map(page =>
      page.map(element => ({ ...element }))
    );

    this.undoStack.push(currentState);
    this.redoStack = [];
  }

  redo() {
    if (this.redoStack.length > 0) {
      const nextState = this.redoStack.pop();
      if (nextState) {
        this.undoStack.push(nextState);
        this.canvasPages = nextState.map(page =>
          page.map(element => ({ ...element }))
        );
        this.elements = this.canvasPages[this.currentPageIndex].map(
          element => ({ ...element })
        );
        this.canvasEditorService.notifyElementsChanged(this.elements);
        this.draw();
      }
    }
  }

  undo() {
    if (this.undoStack.length > 0) {
      const currentState = this.undoStack.pop();
      if (currentState) {
        this.redoStack.push(currentState);
      }
      const previousState = this.undoStack[this.undoStack.length - 1];
      if (previousState) {
        this.canvasPages = previousState.map(page =>
          page.map(element => ({ ...element }))
        );
      } else {
        this.canvasPages[this.currentPageIndex] = this.masterElements;
      }

      this.elements = this.canvasPages[this.currentPageIndex].map(element => ({
        ...element,
      }));
      this.canvasEditorService.notifyElementsChanged(this.elements);
      this.draw();
    }
  }

  initializeCanvasDimensions() {
    const canvasOuterContainer = document.getElementById('canvas-container');
    if (canvasOuterContainer) {
      // new ResizeObserver(() => {
      this.setCanvasDimensions(canvasOuterContainer);
      // }).observe(canvasOuterContainer);
    }
  }

  setCanvasDimensions(canvasOuterContainer: HTMLElement) {
    // this.canvasProperties.canvasWidth = canvasOuterContainer.offsetWidth;
    // this.canvasProperties.canvasHeight = canvasOuterContainer.offsetHeight;
    // this.canvas.nativeElement.width = canvasOuterContainer.offsetWidth;
    // this.canvas.nativeElement.height = canvasOuterContainer.offsetHeight;
  }

  private addZoomEventListener(): void {
    this.canvas.nativeElement.addEventListener('wheel', event => {
      this.zoom(event);
    });
  }

  private zoom(event: WheelEvent): void {
    event.preventDefault();
    const scaleChange = event.deltaY > 0 ? 0.9 : 1.1;

    if (this.currentElement) {
      // Log the image dimensions before resizing
      // if((event.deltaY > 0 && this.currentElement.width <= 50) || (event.deltaY < 0 && this.currentElement.width >= 1000)){
      //   return;
      // }
      // Adjust the size of the selected image
      this.currentElement.width *= scaleChange;
      this.currentElement.height *= scaleChange;

      // Adjust image position to maintain relative position to the mouse
      const mouseX = event.offsetX;
      const mouseY = event.offsetY;
      this.currentElement.x =
        mouseX - (mouseX - this.currentElement.x) * scaleChange;
      this.currentElement.y =
        mouseY - (mouseY - this.currentElement.y) * scaleChange;


      // Update rulers and canvas
      this.draw();
      // this.updateRulers();
    }
  }
  addPage() {
    this.canvasPages.push([]);
    this.selectPage(this.canvasPages.length - 1);
    this.updateCanvasInfo();
  }

  removePage() {
    if (this.canvasPages.length > 1) {
      this.canvasPages.splice(this.currentPageIndex, 1);
      if (this.currentPageIndex >= this.canvasPages.length) {
        this.selectPage(this.canvasPages.length - 1);
      } else {
        this.selectPage(this.currentPageIndex);
      }
      this.updateCanvasInfo();
    } else {
      alert('Cannot remove the last page.');
    }
  }

  updateCanvasInfo() {
    this.totalCanvases = this.canvasPages.length;
    this.activeCanvasIndex = this.currentPageIndex;
  }

  setActiveCanvas(index: number) {
    if (index >= 0 && index < this.canvasPages.length) {
      this.currentPageIndex = index;
      this.elements = this.canvasPages[index];
      this.updateCanvasInfo();
    }
  }

  previousPage() {
    if (this.currentPageIndex > 0) {
      this.selectPage(this.currentPageIndex - 1);
     // this.currentPageIndex--;
    }
  }

  nextPage() {
    if (this.currentPageIndex < this.canvasPages.length - 1) {
      this.selectPage(this.currentPageIndex + 1);
      // this.currentPageIndex++;
    }
  }

  onPageSelect(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedIndex = parseInt(selectElement.value, 10);

    if (
      !isNaN(selectedIndex) &&
      selectedIndex >= 0 &&
      selectedIndex < this.canvasPages.length
    ) {
      this.selectPage(selectedIndex);
    } else {
      console.log('Invalid page index selected:', selectedIndex);
    }
  }

  changeElementOrder(stepUp: number) {
    if (this.currentElement) {
      const currentElementIndex = this.elements.findIndex(
        element => element.id === this.currentElement?.id
      );
      this.elements = this.elements.filter(
        element => element.id !== this.currentElement?.id
      );
      this.elements.splice(
        currentElementIndex + stepUp,
        0,
        this.currentElement
      );
      this.draw();
    }
  }

  zoomOut() {
    const scaleChange = 1.1;
    const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
    const centerX = canvasRect.width / 2;
    const centerY = canvasRect.height / 2;
    this.applyZoom(scaleChange, centerX, centerY);
  }

  zoomIn() {
    const scaleChange = 0.9;
    const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
    const centerX = canvasRect.width / 2;
    const centerY = canvasRect.height / 2;
    this.applyZoom(scaleChange, centerX, centerY);
  }

  reset() {
    this.canvasProperties.scale = 1;
    this.canvasProperties.zoomTranslateX = 0;
    this.canvasProperties.zoomTranslateY = 0;
    this.draw();
  }

  private applyZoom(
    scaleChange: number,
    offsetX: number,
    offsetY: number
  ): void {
    this.canvasProperties.scale *= scaleChange;
    this.canvasProperties.zoomTranslateX =
      offsetX - (offsetX - this.canvasProperties.zoomTranslateX) * scaleChange;
    this.canvasProperties.zoomTranslateY =
      offsetY - (offsetY - this.canvasProperties.zoomTranslateY) * scaleChange;
    this.draw();
  }

  ngOnDestroy(): void {
    this.navBarEventsSubscription.unsubscribe();
    this.elementPropertyChangeSubscription.unsubscribe();
  }
  
  displayComments(){
    this.dialog.open(PopupComponent, {backdropClass:'custom-dialog',
      data: {
        type: 'displayComments',
        comments: this.templateData.comments
      },
      width: '400px'
    });
  }
}