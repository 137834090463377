import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SearchComponent } from "./components/search/search.component";
import { INavButtons } from '@app/shared/models';
import { VerifyTokenService } from '@app/core/verify-token/verify-token.service';
import { MatDialog } from '@angular/material/dialog';
import { SignOutAlertComponent } from '@app/shared/sign-out/sign-out-alert/sign-out-alert.component';
import { MatButton } from '@angular/material/button';
import { SearchService } from './services/search.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [RouterModule, HeaderComponent, FooterComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  navButtons: INavButtons[] = [];
  authService = inject(VerifyTokenService);
  searchService = inject(SearchService);
  readonly dialog = inject(MatDialog);
  readonly router = inject(Router);
  
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.searchService.updateSearchText('');
      }
    });
  }

  onSearchTextChange(searchText: string) {
    this.searchService.updateSearchText(searchText); 
    console.log('Dashboard Search Text:', searchText);
  }

  logout() {
    this.dialog.open(SignOutAlertComponent, {
      width: '350px'
    });
  }

}
