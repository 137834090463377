<div class="right-nav">
  <tbt-tabs>
    <tbt-tab-item title="Properties">
      <div>
        @if (false) {
        <div class="demographic-section">
          <!-- <app-demographic></app-demographic> -->
        </div>
        }
        @if (currentElement) {
          <div class="static-control">
            <label>
              <input type="checkbox" [checked]="currentElement.customizable" (change)="toggleStaticElement()"disabled/>
              Is customizable section?
            </label>
          </div>
          }
          <div class="dimension-controls">
            <div class="dimension-group">
              <label for="x">X:</label>
              <input type="number" id="x" [(ngModel)]="x" [disabled]="currentElement?.type !== 'text'" />
            </div>
            <div class="dimension-group">
              <label for="y">Y:</label>
              <input type="number" id="y" [(ngModel)]="y"  [disabled]="currentElement?.type !== 'text'"/>
            </div>
            <div class="dimension-group">
              <label for="width">Width:</label>
              <input type="number" id="width" [(ngModel)]="width" [readonly]="currentElement?.type !== 'text'"/>
            </div>
            <div class="dimension-group">
              <label for="height">Height:</label>
              <input type="number" id="height" [(ngModel)]="height" [readonly]="currentElement?.type !== 'text'"/>
            </div>
          </div>
        @if(currentElement?.type === 'text'){
        <div class="card mt-4 customization-container">
          <div class="card-header">
            <span>Add Customization</span>
          </div>
          <div class="dropdown">
            <textarea [(ngModel)]="editedText" placeholder="Enter customization text here"
              class="form-control add-text-input text-area-style"></textarea>
              <!-- <select [(ngModel)]="selectedCustomization" class="customization-select">
              @for (customizations of customization; track $index) {
                <option>{{customizations.value}}</option>
              }
              </select> -->
            <button (click)="EditTextElement()" class="apply-button">
              Apply
            </button>
          </div>
          <div class="card-body">
            <div class="row text-align-buttons mb-3">
              <div class="dropdown">
                <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ selectedAlignment }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li><button class="dropdown-item" type="button" (click)="setTextAlign('left')">Left</button></li>
                  <li><button class="dropdown-item" type="button" (click)="setTextAlign('center')">Center</button></li>
                  <li><button class="dropdown-item" type="button" (click)="setTextAlign('right')">Right</button></li>
                </ul>
              </div>
            </div>

            <div class="element-properties">
              <div class="card properties-card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 disp-flex align-center">
                      <label for="color" class="noWrap custm-widt-5 form-label">Select Text Color:</label>
                      <input type="color" id="color" [ngModel]="currentElement?.color"
                        (ngModelChange)="currentElement && (currentElement.color = $event)" class="form-control color-input"
                        (input)="updateElementDimensions()" />
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-12 disp-flex align-items-center">
                      <label for="fontFamily" class="noWrap custm-widt-5 form-label me-2">Font Family:</label>
                      <div class="select-wrapper">
                        <select id="fontFamily" [ngModel]="currentElement?.font_family"
                          (ngModelChange)="currentElement && (currentElement.font_family = $event)" 
                          class="form-control select-input"
                          (change)="updateElementDimensions()">
                        @for (font of fontFamilies; track font) {
                        <option [value]="font">{{ font }}</option>
                        }
                      </select>
                      <svg class="select-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M4.66699 6.66797L8.00033 10.0013L11.3337 6.66797" stroke="#556584" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                </div> 

                  <div class="row mt-3">
                    <div class="col-12 disp-flex align-center">
                      <label for="fontSize" class="noWrap custm-widt-5 form-label">Font Size:</label>
                      <input type="number" id="fontSize" [ngModel]="currentElement?.font_size"
                        (ngModelChange)="currentElement && (currentElement.font_size = $event)" class="form-control number-input"
                        (input)="updateElementDimensions()" />
                    </div>
                  </div>

                  <!-- <div class="row mt-2">
                    <div class="col-12 disp-flex">
                      <label for="backgroundColor" class="noWrap custm-widt-5">Background Color:</label>
                      <input type="color" id="backgroundColor" [ngModel]="currentElement?.backgroundColor"
                        (ngModelChange)="currentElement && (currentElement.backgroundColor = $event)"
                        class="form-control ms-2" (input)="updateElementDimensions()" />
                      <button (click)="removeBackgroundColor()" class="btn btn-link ms-2" style="font-size: 14px;">
                        No Background
                      </button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </tbt-tab-item>
    @if(templateData?.template_type?.toLowerCase() !== 'picklist'){
      <tbt-tab-item title="Mappings" [disabled]="false">
        <div class="mappings-section">
          <app-mappings></app-mappings>
        </div>
      </tbt-tab-item>
      }
  </tbt-tabs>
  <div class="flex-grow"></div>
  <!-- Navigation buttons for layers -->
  <div class="row justify-content-center">
    <div class="col-auto">
      <div class="layer-navigation btn-group" role="group" aria-label="Layer Navigation">
        <button type="button" class="btn btn-secondary btn-lg px-4" (click)="moveLayer('up')">
          <span [innerHTML]="btnIconType('up')"></span>
        </button>
        <button type="button" class="btn btn-secondary btn-lg px-4" (click)="moveLayer('down')">
          <span [innerHTML]="btnIconType('down')"></span>
        </button>
        <button type="button" class="btn btn-secondary btn-lg px-4" (click)="moveLayer('upTop')">
          <span [innerHTML]="btnIconType('upTop')"></span>
        </button>
        <button type="button" class="btn btn-secondary btn-lg px-4" (click)="moveLayer('downTop')">
          <span [innerHTML]="btnIconType('downTop')"></span>
        </button>
      </div>
    </div>
  </div>
</div>