<div class="main-container">
  <div class="filter-search-container">
    <!-- <div class="search-container">
    <input
      type="text"
      id="searchInput"
      placeholder="Search templates..."
      (input)="onSearchInputChange($event)" />
    <svg
      class="search-icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="16"
      height="16"
      viewBox="0 0 48 48">
      <path
        d="M 20.5 6 C 12.509634 6 6 12.50964 6 20.5 C 6 28.49036 12.509634 35 20.5 35 C 23.956359 35 27.133709 33.779044 29.628906 31.75 L 39.439453 41.560547 A 1.50015 1.50015 0 1 0 41.560547 39.439453 L 31.75 29.628906 C 33.779044 27.133709 35 23.956357 35 20.5 C 35 12.50964 28.490366 6 20.5 6 z M 20.5 9 C 26.869047 9 32 14.130957 32 20.5 C 32 23.602612 30.776198 26.405717 28.791016 28.470703 A 1.50015 1.50015 0 0 0 28.470703 28.791016 C 26.405717 30.776199 23.602614 32 20.5 32 C 14.130953 32 9 26.869043 9 20.5 C 9 14.130957 14.130953 9 20.5 9 z"></path>
    </svg>
  </div> -->

    <div class="filter-buttons">
      <span class="title">Designs <small>({{designs.length}})</small></span>
      <div class="filter-container">
        <div class="dropdown">
          <div class="dropdown-selected" (click)="toggleDropdown()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="131"
              height="40"
              viewBox="0 0 131 40"
              fill="none">
              <rect
                x="0.5"
                y="0.5"
                width="129.991"
                height="39"
                fill="white"
                stroke="#E7E7E7" />
            </svg>
            <span class="dropdown-text" id="statusFilter">{{
              selectedStatus || 'All'
            }}</span>
            <div>
              <svg
                class="dropdown-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none">
                <polygon points="0,0 10,0 5,10" fill="#333" />
              </svg>
            </div>
          </div>
          <ul *ngIf="isDropdownOpen" class="dropdown-list">
            <li (click)="selectStatus('All')" class="dropdown-option">All</li>
            <li
              *ngFor="let status of filteredStatusOptions"
              (click)="selectStatus(status)"
              class="dropdown-option">
              {{ status }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <app-spinner></app-spinner>

    <div class="card-box-layout">
      <div class="card-row">
        <ng-container *ngIf="paginatedDesigns.length; else noResults">
          <div
            *ngFor="let design of paginatedDesigns; trackBy: trackByDesignName">
            <div>
              <div class="card" (click)="onCardClick(design)">
                <div class="thumbnail-container">
                  <div
                    class="background-image"
                    [ngStyle]="{
                      'background-image': 'url(' + design.thumbnail + ')',
                      filter: 'grayscale(50%)',
                      'background-position': 'center',
                      'background-size': 'cover',
                      'background-repeat': 'no-repeat',
                    }"></div>
                  <img [src]="design.thumbnail" class="thumbnail" (error)="onImageError($event)"/>
                  <ng-container
                    *ngIf="
                      design.status === 'STANDARDIZED'
                    ">
                    <svg
                      (click)="openDialog(design); $event.stopPropagation()"
                      class="status-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none">
                      <circle cx="15" cy="15" r="15" fill="white" />

                      <g transform="translate(5, 5)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none">
                          <g clip-path="url(#clip0_6289_35299)">
                            <path
                              d="M9.99967 0C4.47707 0 0 4.4774 0 9.9998C0 15.5222 4.47707 20 9.99967 20C15.5223 20 19.9999 15.5225 19.9999 9.9998C19.9999 4.47713 15.5223 0 9.99967 0ZM14.7534 7.19633L13.8036 8.14613L11.8702 6.21287L11.1367 6.94633L13.0701 8.87973L8.328 13.6215L6.39473 11.6884L5.66127 12.4219L7.59453 14.3551L7.122 14.8277L7.113 14.8187C7.0606 14.9049 6.9754 14.9679 6.8742 14.9903L5.07133 15.3923C5.04433 15.3984 5.01687 15.4013 4.98973 15.4013C4.89133 15.4013 4.79573 15.3626 4.72487 15.2915C4.63393 15.2009 4.59607 15.0701 4.62407 14.9447L5.02587 13.1423C5.04853 13.0412 5.1116 12.9558 5.19773 12.9035L5.1886 12.8944L12.8199 5.26273C12.9318 5.15107 13.1135 5.15107 13.2254 5.26307L14.7535 6.79087C14.8654 6.90273 14.8654 7.08447 14.7534 7.19633Z"
                              fill="#0E9888" />
                          </g>
                          <defs>
                            <clipPath id="clip0_6289_35299">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </g>
                    </svg>
                  </ng-container>
                  <ng-container *ngIf="design.status === 'IN_USE'">
                    <svg
                      class="design-lock"
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none">
                      <g clip-path="url(#clip0_6339_37891)">
                        <path
                          d="M30.8749 14.9106V11.875C30.8749 5.32753 25.5474 0 19 0C12.4525 0 7.12498 5.32753 7.12498 11.875V14.9106C5.7121 15.7344 4.75 17.2494 4.75 19V33.25C4.75 35.8697 6.88028 37.9999 9.49996 37.9999H28.5C31.1197 37.9999 33.2499 35.8697 33.2499 33.25V19C33.2499 17.2493 32.2878 15.7343 30.8749 14.9106ZM19 4.74996C22.9289 4.74996 26.125 7.94596 26.125 11.875V14.25H11.8749V11.875C11.8749 7.94604 15.071 4.74996 19 4.74996ZM21.3749 27.5754V30.875H16.625V27.5754C15.901 26.9234 15.4375 25.9884 15.4375 24.9375C15.4375 22.9699 17.0324 21.375 19 21.375C20.9675 21.375 22.5624 22.9699 22.5624 24.9375C22.5624 25.9884 22.0989 26.9234 21.3749 27.5754Z"
                          fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_6339_37891">
                          <rect width="38" height="38" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </ng-container>
                </div>
                <div class="card-body">
                  <p class="card-text name">{{ design.design_name }}</p>
                  <p class="card-text jobcode">{{ design.job_code }}</p>
                  <p class="card-text status">{{ design.status }}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noResults class="no-results-message">
          <p>No designs found.</p>
        </ng-template>
      </div>
    </div>
    <div class="pagination-controls">
      <div class="canvas-controls">
        <div class="pagination">
          <div class="previous">
            <button
              class="previous-button"
              (click)="previousPage()"
              [disabled]="isFirstPage()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none">
                <path
                  d="M1.67383 8.00131C1.67383 7.83396 1.73549 7.68102 1.83733 7.56396C1.85572 7.54281 1.87551 7.52273 1.89666 7.50386C1.89878 7.50197 1.90091 7.50009 1.90305 7.49823L7.23482 2.83293C7.51192 2.59047 7.93309 2.61855 8.17555 2.89564C8.418 3.17273 8.38992 3.59391 8.11283 3.83636L4.11478 7.33465H14.3405C14.7087 7.33465 15.0072 7.63313 15.0072 8.00132C15.0072 8.36951 14.7087 8.66799 14.3405 8.66799L4.1148 8.66799L8.11283 12.1663C8.38992 12.4087 8.418 12.8299 8.17555 13.107C7.93309 13.3841 7.51192 13.4122 7.23482 13.1697L1.90518 8.50626C1.7635 8.384 1.67383 8.20313 1.67383 8.00131C1.67383 8.00132 1.67383 8.00131 1.67383 8.00131Z"
                  fill="#00857C" />
              </svg>
              Previous
            </button>
          </div>
          <div class="page-buttons">
            <ng-container *ngFor="let i of getVisiblePages()">
              <ng-container *ngIf="i === -1">
                <button class="page-number" disabled>...</button>
              </ng-container>
              <ng-container *ngIf="i !== -1">
                <button
                  class="page-number"
                  [ngClass]="{ active: currentPageIndex === i }"
                  (click)="selectPage(i)">
                  {{ i + 1 }}
                </button>
              </ng-container>
            </ng-container>
          </div>
          <div class="next">
            <button
              class="next-button"
              (click)="nextPage()"
              [disabled]="isLastPage()">
              Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none">
                <path
                  d="M15.0072 8.00131C15.0072 8.16866 14.9455 8.3216 14.8437 8.43867C14.8253 8.45981 14.8055 8.47989 14.7843 8.49876C14.7822 8.50065 14.7801 8.50253 14.7779 8.50439L9.44616 13.1697C9.16907 13.4122 8.7479 13.3841 8.50544 13.107C8.26299 12.8299 8.29107 12.4087 8.56816 12.1663L12.5662 8.66797L2.34049 8.66797C1.97231 8.66797 1.67383 8.36949 1.67383 8.0013C1.67383 7.63311 1.97231 7.33464 2.34049 7.33464L12.5662 7.33464L8.56816 3.83636C8.29107 3.59391 8.26299 3.17273 8.50544 2.89564C8.7479 2.61855 9.16907 2.59047 9.44616 2.83293L14.7758 7.49637C14.9175 7.61862 15.0072 7.7995 15.0072 8.00131C15.0072 8.00131 15.0072 8.00131 15.0072 8.00131Z"
                  fill="#00857C" />
              </svg>
            </button>
          </div>
        </div>
        <div class="items-per-page">
          <label for="itemsPerPage">Per page</label>
          <div class="select-container">
            <select
              id="itemsPerPage"
              [(ngModel)]="itemsPerPage"
              (change)="onItemsPerPageChange($event)">
              <option
                *ngFor="let option of itemsPerPageOptions"
                [value]="option">
                {{ option }}
              </option>
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              class="filter-dropdown-icon">
              <path
                d="M6.40113 8.33203L0.92653 0.83203L11.8757 0.832031L6.40113 8.33203Z"
                fill="#333333" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
